import React, { useEffect, useState, useRef } from "react";

import {
  Grid,
  // Typography,
  Button,
  TextField,
  MenuItem,
  Pagination,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Parser from "html-react-parser";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import i18n from "i18next";

import { getTransactionsStart } from "../../../redux-saga/redux/profile/transactions";
import useStyles from "./Transaction.styles";
import {
  EAR_TRANSACTION_TYPE,
  DATE_FORMAT,
  TRANSACTION_LIMIT,
} from "../../../utils/constants";
const Transaction = () => {
  const { transactions, totalPages, loading } = useSelector(
    (state) => state.transactions
  );
  const { sectionData } = useSelector((state) => state.section);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const offset = useRef(0);
  const [page, setPage] = useState(1);
  const [txnFilters, setTxnFilters] = useState({
    fromDate: null,
    endDate: null,
    activityType: "5",
  });

  useEffect(() => {
    if (offset.current === 0) {
      dispatch(getTransactionsStart({ page: 1, limit: TRANSACTION_LIMIT }));
      offset.current = 1;
    }
  }, [dispatch]);

  const transactionFilters = () => {
    const filters = {};
    if (txnFilters.fromDate) {
      filters.dateFrom = dayjs(txnFilters.fromDate).format(DATE_FORMAT);
    }
    if (txnFilters.endDate) {
      filters.dateTo = dayjs(txnFilters.endDate).format(DATE_FORMAT);
    }
    if (txnFilters.activityType !== "5") {
      filters.transactionType = txnFilters.activityType;
    }
    return filters;
  };

  const handlePageChange = (event, value) => {
    const filters = transactionFilters();
    dispatch(
      getTransactionsStart({
        ...filters,
        page: value,
        limit: TRANSACTION_LIMIT,
      })
    );
    setPage(value);
  };

  const filterData = () => {
    const filters = transactionFilters();
    dispatch(
      getTransactionsStart({ ...filters, page: 1, limit: TRANSACTION_LIMIT })
    );
    setPage(1);
  };
  console.log("djkdg", sectionData);
  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        {/* <Typography variant="h4" gutterBottom>
          {t("TRANSACTIONS.TRANSACTIONS_UPPER")}
        </Typography> */}
        <h4 className="profile-heading">
          {" "}
          {t("TRANSACTIONS.TRANSACTIONS_UPPER")}
        </h4>
        <br></br>
        <Grid className={classes.myprofileForm}>
          <Grid>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", padding: "10px 0" }}
            >
              {sectionData.map((section) => (
                <Grid key={section.id}> {Parser(section?.content)}</Grid>
              ))}
            </Typography>
          </Grid>

          <Grid className={classes.signupFromFname}>
            <Grid container spacing={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n?.language || "en"}
              >
                <Grid item md={3} xs={12}>
                  <div className={classes.formGroup}>
                    <DesktopDatePicker
                      id="start-date"
                      label={t("TRANSACTIONS.FROM_DATE")}
                      variant="outlined"
                      disableFuture
                      value={txnFilters.fromDate}
                      inputFormat={DATE_FORMAT}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(fromDate) => {
                        setTxnFilters((current) => ({
                          ...current,
                          fromDate,
                        }));
                      }}
                      maxDate={txnFilters.endDate || null}
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <div className={classes.formGroup}>
                    <DesktopDatePicker
                      id="end-date"
                      label={t("TRANSACTIONS.TO_DATE")}
                      variant="outlined"
                      inputFormat={DATE_FORMAT}
                      disableFuture
                      value={txnFilters.endDate}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(endDate) => {
                        setTxnFilters((current) => ({
                          ...current,
                          endDate,
                        }));
                      }}
                      minDate={txnFilters.fromDate || null}
                    />
                  </div>
                </Grid>
              </LocalizationProvider>
              <Grid item md={3} xs={12}>
                <div className={classes.formGroup}>
                  <TextField
                    id="activity-type"
                    label={t("TRANSACTIONS.ACTIVITY_TYPE")}
                    variant="outlined"
                    select
                    value={txnFilters.activityType}
                    onChange={(e) => {
                      setTxnFilters((current) => ({
                        ...current,
                        activityType: e.target.value,
                      }));
                    }}
                  >
                    <MenuItem value={"5"}>{t("TRANSACTIONS.ALL")}</MenuItem>
                    <MenuItem value={"3"}>{t("TRANSACTIONS.DEPOSIT")}</MenuItem>
                    <MenuItem value={"19"}>
                      {t("TRANSACTIONS.JOINING_BONUS")}
                    </MenuItem>
                    <MenuItem value={"4"}>
                      {t("TRANSACTIONS.WITHDRAW")}
                    </MenuItem>
                  </TextField>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  onClick={() => filterData()}
                  className={classes.btnPrimary}
                >
                  {t("COMMON.SHOW")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        {loading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : transactions.length > 0 ? (
          <>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("TRANSACTIONS.DATE_TIME")}</TableCell>
                      <TableCell>{t("TRANSACTIONS.TYPE")}</TableCell>
                      <TableCell>{t("TRANSACTIONS.AMOUNT")}</TableCell>
                      <TableCell>{t("TRANSACTIONS.STATUS")}</TableCell>
                      <TableCell>{t("TRANSACTIONS.COMMENTS")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((transaction) => (
                      <TableRow key={transaction.id}>
                        <TableCell>
                          {dayjs(transaction.createdAt).format(
                            `${DATE_FORMAT} HH:mm:ss`
                          )}
                        </TableCell>
                        <TableCell>
                          {t(
                            EAR_TRANSACTION_TYPE[transaction.transactionType]
                          ) || ""}
                        </TableCell>
                        <TableCell>{transaction.amount.toFixed(2)}</TableCell>
                        <TableCell>{transaction.status}</TableCell>
                        <TableCell>{transaction.comments}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Stack alignItems="center">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
              />
            </Stack>
          </>
        ) : (
          <Stack alignItems="center">
            <h2>{t("COMMON.NO_TRANSACTION_MSG")}</h2>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default Transaction;
