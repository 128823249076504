import { put, takeEvery } from "redux-saga/effects";

import sectionService from "../../../services/section";
import {
  getSectionStart,
  getSectionSuccess,
  getSectionFailure,
} from "../../redux/section";
import { showErrorMessage } from "../../redux/toast";

function* sectionWatcher() {
  yield takeEvery(getSectionStart.type, sectionWorker);
}

function* sectionWorker(action) {
  console.log(action.payload);
  try {
    const { data, error } = yield sectionService.getSectionData(action.payload);
    if (!error) {
      yield put(getSectionSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getSectionFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getSectionFailure());
  }
}

export default sectionWatcher;
