import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: null,
  transactions: [],
  totalPages: 0,
  totalBets: 0,
  bets: [],
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getTransactionsStart: (state, action) => {
      state.loading = true;
    },
    getTransactionsSuccess: (state, action) => {
      state.loading = false;
      state.transactions = action.payload.transactionList;
      state.totalPages = action.payload.totalPage;
    },
    getTransactionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllBetsStart: (state) => {
      state.loading = true;
    },
    getAllBetsSuccess: (state, action) => {
      state.bets = action.payload?.transactionList;
      state.totalPages = action.payload?.totalPage;
      state.loading = false;
      state.success = true;
    },
    getAllBetsStartFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetTransactionState: (state) => {
      state.transactions = initialState.transactions;
      state.bets = initialState.bets;
    },
  },
});

export const {
  getTransactionsStart,
  getTransactionsSuccess,
  getTransactionsFailure,
  getAllBetsStart,
  getAllBetsSuccess,
  getAllBetsStartFailure,
  resetTransactionState,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
