import React, { useEffect } from "react";

import { Grid, Typography, MenuItem, Select, Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { useTranslation } from "react-i18next";

import useStyles from "./Lobby.styles";
import { formatCategoryName } from "../../../utils/helper";
import {
  getProvidersStart,
  getSubCategoryStart,
} from "../../../redux-saga/redux/casino/lobby/casinoLobby";
import CategoryGames from "./CategoryGames";
import { getSectionStart } from "../../../redux-saga/redux/section";
import { resetIsGameStarted } from "../../../redux-saga/redux/casino/lobby/casinoGames";
import { updateParams } from "../../../redux-saga/redux/profile/myProfile";

const element = document.getElementsByTagName("html")[0];

const Lobby = () => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { providers, subCategory } = useSelector((state) => state.casinoLobby);
  const { sectionData } = useSelector((state) => state.section);
  const param = useParams();
  //console.log("parqqqams", param);
  const location = useLocation();
  //console.log(location);
  const paramarr = location.search.split("?affiliate=");
  const affiliateCode = paramarr.length === 2 && paramarr[1];
  // console.log("affiliateCode", location.search.split("?affiliateCode="));
  useEffect(() => {
    dispatch(getSubCategoryStart());
    dispatch(getProvidersStart());
    dispatch(resetIsGameStarted());
    dispatch(
      updateParams({
        referralId: param?.referralId,
        affiliateCode: affiliateCode,
      })
    );
  }, [dispatch, param?.referralId, affiliateCode]);

  useEffect(() => {
    const params = {
      displayPage: "homePage",
      contentType: 2,
      language: "es",
    };
    dispatch(getSectionStart(params));
  }, [dispatch]);
  console.log("sectionData", sectionData);
  return (
    <div className={classes.myclass}>
      <Grid className={classes.homeBannerWrap}>
        <Grid className={classes.homeBanner}>
          <Grid className={classes.bannerContent} style={{ width: "350px" }}>
            <Grid className={classes.bannerBadge}>WELCOME BONUS</Grid>
            <Typography variant="h1" gutterBottom>
              {sectionData[0]?.content}
            </Typography>
            {/* <Typography>
              Lorem Ipsum is simply dummy text of the printing and typesettin
            </Typography> */}
          </Grid>
          <Grid className={classes.bannerRightImg}>
            <img src="/images/banner-right-img.png" alt="" />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.homeFilterContentWrap}>
        <Grid className={classes.homeFilterWrap}>
          <Select
            id="lobby-providers"
            value={0}
            className={classes.providerOptions}
            onOpen={() => element.classList.add("disable-scroll")}
            onClose={() => element.classList.remove("disable-scroll")}
          >
            {/* <option value={0} >---Select a Provider---</option> */}
            <MenuItem disabled value={0} className={classes.defaultOption}>
              Providers
            </MenuItem>
            {providers.map((item) => {
              return (
                <MenuItem
                  key={item.masterCasinoProviderId}
                  onClick={() => {
                    element.classList.remove("disable-scroll");
                    navigate(
                      `/view-all/${item.masterCasinoProviderId}/false/${item.name}`
                    );
                  }}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>

          <Tabs
            value={false}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
          >
            {subCategory?.map((category) => {
              return (
                <Tab
                  onClick={() => {
                    navigate(
                      `/view-all/false/${category.masterGameSubCategoryId}/${category.name}`
                    );
                  }}
                  key={category.masterGameSubCategoryId}
                  label={formatCategoryName(category.name)}
                />
              );
            })}
          </Tabs>
        </Grid>
      </Grid>
      <CategoryGames />
    </div>
  );
};

export default Lobby;
