import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    marginLeft: "5px",
  },
  btnPrimary: {
    background: "#FFCE10",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background: "#f1c311",
    },
  },
  forgotForm: {
    maxWidth: "320px",
    margin: "0 auto",
  },
  forgotFormWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "13px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  forgotFormContainer: {
    background: " #091251",
    borderRadius: "10px",
    marginTop: "10px",
    padding: "20px 40px",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#FFCE10",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "20px",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
}));
