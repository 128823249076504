import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  modal: {
    backgroundColor: "#091251",
  },
  modalheader: {
    padding: "1rem",
    backgroundColor: "#000000",
  },
}));
