import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  sectionData: [],
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    getSectionStart: (state, action) => {
      console.log("actionnnnnnn", action.payload);
      state.loading = true;
    },
    getSectionSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.sectionData = action.payload;
    },
    getSectionFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { getSectionStart, getSectionSuccess, getSectionFailure } =
  sectionSlice.actions;

export default sectionSlice.reducer;
