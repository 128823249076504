import { put, takeEvery } from "redux-saga/effects";
import { isMobile } from "react-device-detect";

import lobbyService from "../../../../services/casino/lobby";
import {
  getGamesStart,
  getGamesSuccess,
  getGamesFailure,
  getGameLinkStart,
  getGameLinkSuccess,
  getGameLinkFailure,
  getSearchedGamesStart,
  getSearchedGamesSuccess,
  getSearchedGamesFailure,
} from "../../../redux/casino/lobby/casinoGames";
import { showErrorMessage } from "../../../redux/toast";

function* casinoGamesWatcher() {
  yield takeEvery(
    [getGamesStart.type, getGameLinkStart.type, getSearchedGamesStart.type],
    casinoGamesWorker
  );
}

function* casinoGamesWorker(action) {
  try {
    switch (action.type) {
      case getGamesStart.type: {
        const { data, error } = yield lobbyService.getAllGames({
          ...action.payload,
          loadMoreGames: null,
          isMobile: false,
        });
        if (!error) {
          yield put(
            getGamesSuccess({
              games: data.games,
              numberOfPages: data.numberOfPages,
              gamesCount: data.queriedCount,
              loadMoreGames: action.payload?.loadMoreGames,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(getGamesFailure());
        }
        break;
      }

      case getGameLinkStart.type: {
        const { data, error } = yield lobbyService.getGameLink(action.payload);
        if (!error) {
          yield put(getGameLinkSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(getGameLinkFailure({ redirection: true }));
        }
        break;
      }

      case getSearchedGamesStart.type: {
        const { data, error } = yield lobbyService.getAllSearchedGames({
          ...action.payload,
          loadMoreSeachedGames: null,
          isMobile,
        });
        if (!error) {
          yield put(
            getSearchedGamesSuccess({
              searchGames: data.games,
              searchNoOfPages: data.numberOfPages,
              searchCount: data.queriedCount,
              loadMoreSeachedGames: action.payload?.loadMoreSeachedGames,
            })
          );
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(getSearchedGamesFailure());
        }
        break;
      }

      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getGamesStart.type: {
        yield put(getGamesFailure());
        break;
      }
      case getGameLinkStart.type: {
        yield put(getGameLinkFailure({ redirection: true }));
        break;
      }
      default:
        break;
    }
    yield put(showErrorMessage({ msg: error.message }));
  }
}

export default casinoGamesWatcher;
