import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarOpen: false,
  orientation: "PORTRAIT",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.sidebarOpen = action.payload;
    },
    currentOrientation: (state, action) => {
      state.orientation = action.payload;
    },
  },
});

export const { toggleSidebar, currentOrientation } = settingsSlice.actions;

export default settingsSlice.reducer;
