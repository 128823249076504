/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import useStyles from "./Footer.styles";
import { getCmsStart } from "../../redux-saga/redux/cms";

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { cmsData } = useSelector((state) => state.cms);
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCmsStart());
  }, [dispatch]);

  if (isGameStarted && isMobile) {
    return;
  }
  const handleLinkClick = (e) => {
    e.preventDefault();
    const url = e.target.href;
    window.open(url, "_blank");
  };
  return (
    <div>
      <Grid className={classes.footerWrap}>
        {/* <Grid className={classes.footerLogo}>
          <img
            className={classes.LogoSize}
            src="/images/brand-logo.jpg"
            alt="Brand Logo"
          />
        </Grid> */}
        <Grid className={classes.footerLinking}>
          {/* <Link href="#">{t("HEADER.SPORTS_BETS")}</Link> */}
          <Button
            onClick={() => navigate("/")}
            className={`${classes.footerBtn} ${
              location.pathname === "/" ? classes.activeLink : null
            }`}
            to="/"
          >
            {t("HEADER.CASINO")}
          </Button>
          {/* <Link
            className={`${classes.headerLink} ${
              location.pathname === "/liveCasino" ? classes.activeLink : null
            }`}
            to="/liveCasino"
          >
            {t("HEADER.LIVE_CASINO")}
          </Link>
          <Link
            className={`${classes.headerLink} ${
              location.pathname === "/promotions" ? classes.activeLink : null
            }`}
            to="/promotions"
          >
            {t("HEADER.PROMOTIONS")}
          </Link> */}
          {cmsData.length > 0
            ? cmsData.map((item) => (
                <Button
                  varient="outlined"
                  key={item.id}
                  style={{ backgroundColor: "none" }}
                  onClick={() =>
                    navigate(`/cms/${item.id}/${item.slug}`, {
                      state: item.content,
                    })
                  }
                  className={`${classes.footerBtn} ${
                    location.pathname === `/cms/${item.id}/${item.slug}`
                      ? classes.activeLink
                      : null
                  }`}
                >
                  {item.title}
                </Button>
              ))
            : null}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid className={classes.footerLogo}>
              <img
                className={classes.LogoSize}
                src="/images/brand-logo.svg"
                alt="Brand Logo"
              />{" "}
            </Grid>

            <Grid className={classes.gameImage}>
              {/* <img src="images/footer-game.png" /> */}
              <iframe
                src="https://licensing.gaming-curacao.com/validator/?lh=8aecdce9a289027871fb29d804b448be&template=tseal"
                width="200"
                height="120"
                style={{ border: "none", padding: "25px 35px" }}
                title="licensing"
              ></iframe>
            </Grid>

            <Grid className={classes.footerItem}>
              <List>
                <ListItem>
                  <Typography>
                    <img src="/images/dollar-ico.svg" alt="dollar" />
                    License
                  </Typography>
                  <Typography>
                    <Link
                      to="https://www.curacao-egaming.com"
                      onClick={handleLinkClick}
                    >
                      Curacao certified
                    </Link>
                    <Link to="/">GLH+</Link>
                  </Typography>
                </ListItem>

                <ListItem>
                  <Typography>
                    <img src="/images/dollar-ico.svg" alt="dollar" />
                    License
                  </Typography>
                  <Typography>
                    <Link to="/">GLH+</Link>
                    <Link to="/">GLH+</Link>
                  </Typography>
                </ListItem>

                <ListItem>
                  <Typography>
                    <img src="/images/dollar-ico.svg" alt="dollar" />
                    Website
                  </Typography>
                  <Typography>
                    <Link to="/">GLH+</Link>
                    {/* <Link to="/">
                      GLH+
                    </Link> */}
                  </Typography>
                </ListItem>
              </List>
            </Grid>

            <Grid className={classes.quickLinksScroll}>
              <Grid className={classes.quickLinksWrap}>
                {cmsData.length > 0 &&
                  cmsData.map((item) => (
                    <Link key={item.id} to={`/cms/${item.id}/${item.slug}`}>
                      {item.slug}
                    </Link>
                  ))}
                <Link
                  to="https://www.curacao-egaming.com"
                  onClick={handleLinkClick}
                >
                  Curacao certified
                </Link>
                <Grid className="icon18-box">
                  <img
                    className={classes.LogoSize}
                    src="/images/18.png"
                    alt="18 Logo"
                    width={30}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={7}>
            <Grid className={classes.quickLinksWrap}>
              <Grid className={classes.quickLinks}>
                <Typography variant="h6" gutterBottom>
                  PLATFORM
                </Typography>
                <Link href="#">SUPPORT</Link>
                <Link href="#">FAQ</Link>
                <Link href="#">PARTNERSHIP PROGRAM</Link>
                <Link href="#">BLOG</Link>
                <Link href="#">HELP CENTER</Link>
              </Grid>
              <Grid className={classes.quickLinks}>
                <Typography variant="h6" gutterBottom>
                  PLATFORM
                </Typography>
                <Link href="#">AML POLICY</Link>
                <Link href="#">SPORTS POLICY</Link>
                <Link href="#">RESPONSIBLE GAMBLING</Link>
                <Link href="#">PRIVACY POLICY</Link>
                <Link href="#">TERMS & CONDITIONS</Link>
              </Grid>
              <Grid className={classes.socailLinks}>
                <Typography variant="h6" gutterBottom>
                  GET IN TOUCH
                </Typography>
                <Link href="#">
                  <img src="/images/twitch-ico.svg" alt="Twitch" /> DISCORD
                </Link>
                <Link href="#">
                  <img src="/images/twitter-ico.svg" alt="Twitter" /> TWITTER
                </Link>
                <Link href="#">
                  <img src="/images/insta-ico.svg" alt="Instagram" /> INSTAGRAM
                </Link>
                <Link href="#">
                  <img src="/images/facebook-ico.svg" alt="facebook" /> Facebook
                </Link>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>

      <Grid className={classes.footerCopyright}>
        {/* <Typography>{t("FOOTER.COPYRIGHT_MSG")}</Typography> */}
        <Typography>Copyright@2024 megavega.io All rights reserved.</Typography>
      </Grid>
    </div>
  );
};

export default Footer;
