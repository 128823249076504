import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logoSize: {
    width: "60px",
  },
  homeBannerWrap: {
    padding: "90px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "90px 15px 20px 10px",
    },
  },
  homeBanner: {
    backgroundImage: "url('/images/banner-bg.png')",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    minHeight: "240px",
    display: "flex",
    alignItems: "center",
    borderRadius: "16px",
    position: "relative",
    margin: "30px 0 50px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
    },
  },
  bannerRightImg: {
    position: "absolute",
    right: "0",
    top: "-40px",
    [theme.breakpoints.down("md")]: {
      top: "-20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        width: "340px",
      },
    },
  },
  bannerBadge: {
    background: "#000",
    borderRadius: "8px",
    padding: "5px 10px",
    display: "inline-block",
    marginBottom: "15px",
  },
  bannerContentSubText: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      alignItems: "flex-start",
    },
  },
  btnWrap: {
    margin: "10px 0",
    display: "block",
  },
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    textDecoration: "none",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  btnSecondary: {
    background: " #091251",
    borderRadius: "10px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    textDecoration: "none",
    textTransform: "capitalize",
    "&:hover": {
      background: "#360359",
      color: "#fff",
    },
  },
  categoryTab: {
    textDecoration: "none",
    marginRight: "5px",
    color: "#fff",
    padding: "10px 30px",
    // height: "40px",
    background: "#5a0073",
    borderRadius: "30px",
    fontSize: "13px",
    "&:hover": {
      background: "#FFCE10",
      color: "#000",
    },
  },
  bannerContent: {
    padding: "30px 0 30px 60px",
    "& .MuiTypography-h1": {
      fontWeight: "600",
      fontSize: "36px",
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
      },
      "& br": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& img": {
      marginRight: "5px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0",
        width: "100px",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "14px",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        maxWidth: "340px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        maxWidth: "100%",
      },
    },
    "& .MuiButton-textPrimary": {
      padding: "6px 40px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 0 30px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
      textAlign: "center",
    },
  },
  homeOptions: {
    width: "100%",
    padding: "30px 15px",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "30px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
    },
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
  homeFilter: {
    width: "100%",
    display: "none",
    justifyContent: "space-between",
    padding: "30px 100px",
    marginRight: "auto",
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "30px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
      overflowX: "auto",
    },
    "& .MuiInputBase-colorPrimary": {
      width: "140px",
      background: " #091251",
      borderRadius: "10px",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "100px",
        fontSize: "12px",
      },
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      // background: "#FFCE10",
      color: "#fff",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "& .tabsContainer": {
      width: "calc(100% - 160px)",
      backgroundColor: "#36005b57",
      borderRadius: "10px",
      padding: "5px 0",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 120px)",
      },
    },
  },
  contentWrap: {
    padding: "30px 15px",
    // [theme.breakpoints.down("md")]: {
    //   padding: "30px 15px",
    // },
    "& .gamesOuter": {
      width: "100%",
      // backgroundColor: "#241a457d",
      // borderRadius: "0 20px 20px 20px",
      // padding: "0 16px 16px 0",
      marginLeft: "0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 15px 30px 0",
    },
  },
  casinoCard: {
    height: "auto",
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      // height: "330px",
    },
    "& .imgBox": {
      // position: "relative",
      // width: "100%",
      height: "230px",
      overflow: "hidden",
      margin: "0 auto",
      "&:hover img": {
        transform: "scale(1.3) rotate(20deg)",
      },
      "&:hover .playBtnBox": {
        opacity: "1",
      },
      "& img": {
        // position: "absolute",
        // top: "0",
        // left: "0",
        // bottom: "0",
        // right: "0",
        // objectFit: "cover",
        objectPosition: "center",
        width: "100%",
        height: "100%",
        transition: "0.3s all ease-in-out",
      },
      "& .squareBox": {
        display: "block",
        paddingTop: "100%",
      },
      "& .playBtnBox": {
        width: "100%",
        height: "100%",
        background:
          " linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: "0",
        transition: "0.3s all ease-in-out",
        position: "absolute",
        top: "0",
        left: "0",
        "& .playBtn": {
          background: "transparent",
          border: "0",
          color: "#000",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          "& svg": {
            width: "50px",
            height: "auto",
            marginBottom: "15px",
            [theme.breakpoints.down("lg")]: {
              width: "30px",
            },
          },
          "& .text": {
            fontSize: "18px",
            fontWeight: "bold",
            [theme.breakpoints.down("lg")]: {
              fontSize: "14px",
            },
          },
        },
      },
    },
    // "& img": {
    //   height: "100%",
    //   width: "100%",
    // },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "120px",
      },
    },
  },
  casinoOverlay: {
    marginBottom: "10px",
    marginTop: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    position: "relative",
    "& .MuiTypography-body1": {
      paddingRight: "10px",
      fontSize: "14px",
    },
  },
  infoBtn: {
    position: "relative",
    "&:hover": {
      "& > .MuiGrid-root": {
        display: "block",
      },
    },
    "& img": {
      height: "20px",
    },
  },
  customInfoModal: {
    borderRadius: "10px",
    background: "#050925",
    position: "absolute",
    minWidth: "250px",
    left: "-80px",
    right: "auto",
    top: "-100px",
    padding: "5px",
    zIndex: "5",
    display: "none",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("md")]: {
      left: "-92px",
      top: "-80px",
    },
    [theme.breakpoints.down("sm")]: {
      left: "-100px",
      minWidth: "220px",
      top: "-70px",
    },
  },
  customInfoCard: {
    padding: "10px",
    background: "#424582",
    margin: "5px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      fontSize: "12px",
      color: "#bbb7b7",
      paddingRight: "10px",
    },
    "& .MuiTypography-body1": {
      fontSize: "12px",
      color: "#fff",
      paddingRight: "0",
    },
  },
  innerHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  innerHeadingLeft: {
    borderRadius: "20px 20px 0 0",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    "& img": {
      marginRight: "5px",
      width: "20px",
    },
    "& .MuiTypography-h6": {
      margin: "0",
      color: "#FFF",
      fontWeight: "bold",
      fontSize: "24px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  noGamesMsg: {
    textAlign: "center",
  },
  categoryLoader: {
    margin: "40px",
  },
  homeFilterContentWrap: {
    padding: "0 20px 0 20px",
  },
  homeFilterWrap: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      rowGap: "1rem",
      "& .MuiTabs-scrollButtons.Mui-disabled": {
        display: "none",
      },
    },
    "& button": {
      textDecoration: "none",
      color: "#7D7DE2",
      fontSize: "14px",
      padding: "10px 20px",
      borderRadius: "30px",
      display: "flex",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.5)",
        color: "#fff",
      },
    },
    "& .MuiTabs-scroller .MuiButtonBase-root": {
      fontSize: "14px",
    },
  },
  providerOptions: {
    borderRadius: "30px",
    padding: "10px 30px",
    fontSize: "14px",
    background: "#070a29",
    marginRight: "10px",
    position: "relative",
    color: "#fff",
    "& .MuiSvgIcon-root": {
      position: "absolute",
      left: "auto",
      right: "20px",
      cursor: "pointer",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-select": {
      padding: "0  20px 0 0!important",
    },
    "& .MuiSelect-outlined": {
      paddingRight: "0",
    },
  },
  defaultOption: {
    backgroundColor: "transparent !important",
    color: "#fff",
    opacity: "1 !important",
  },
}));
