import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  metamaskLoading: false,
  metamaskConnectLoading: false,
  isLoggedIn: false,
  metamaskLoggedIn: false,
  metamaskConnected: false,
  success: false,
  metamaskSuccess: false,
  metamaskConnectSuccess: false,
  signup: false,
  error: null,
  walletAddress: "",
  userId: "",
  refreshTokenExpired: false,
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    refreshLoginStart: (state, action) => {
      state.loading = true;
    },
    refreshLoginSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.isLoggedIn = true;
      state.metamaskConnected = action.payload.walletAddress ?? false;
      state.userId = action.payload.id;
    },
    refreshLoginFailure: (state, action) => {
      state.loading = false;
      state.metamaskLoading = false;
      state.metamaskConnectLoading = false;
      state.error = action.payload;
    },
    connectMetamaskStart: (state, action) => {
      state.metamaskConnectLoading = true;
    },
    connectMetamaskSuccess: (state, action) => {
      state.metamaskConnectLoading = false;
      state.metamaskConnectSuccess = true;
      state.metamaskConnected = true;
      if (action.payload?.walletAddress) {
        state.walletAddress = action.payload.walletAddress;
      }
    },
    connectMetamaskFailure: (state, action) => {
      state.metamaskConnectLoading = false;
      state.walletAddress = "";
      state.metamaskConnected = false;
    },
    logoutStart: (state, action) => {
      state.loading = true;
    },
    logoutSuccess: (state, action) => {
      state.loading = false;
      state.metamaskLoading = false;
      state.success = true;
      state.isLoggedIn = false;
      state.metamaskConnected = false;
      state.metamaskLoggedIn = false;
      state.userId = "";
      state.walletAddress = "";
    },
    logoutFailure: (state, action) => {
      state.loading = false;
    },
    verifyEmailStart: (state, action) => {
      state.loading = true;
    },
    verifyEmailSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    verifyEmailFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    forgotPasswordStart: (state, action) => {
      state.loading = true;
    },
    forgotPasswordSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    forgotPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    verifyPasswordTokenStart: (state, action) => {
      state.loading = true;
    },
    verifyPasswordTokenSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    verifyPasswordTokenFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetSuccess: (state, action) => {
      state.success = false;
      state.signup = false;
    },
    resetError: (state, action) => {
      state.error = null;
    },
    walletConnectLoginStart: (state, action) => {
      state.loading = true;
    },
    walletConnectLoginSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.isLoggedIn = true;
      state.userId = action.payload;
    },
    walletConnectLoginFailure: (state, action) => {
      state.loading = false;
    },
    refreshTokenExpire(state, action) {
      state.refreshTokenExpired = action.payload;
    },
  },
});

export const {
  refreshLoginStart,
  refreshLoginSuccess,
  refreshLoginFailure,
  loginFailure,
  loginSuccess,
  signUpSuccess,
  signUpFailure,
  logoutStart,
  logoutSuccess,
  logoutFailure,
  verifyEmailStart,
  verifyEmailSuccess,
  verifyEmailFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyPasswordTokenStart,
  verifyPasswordTokenSuccess,
  verifyPasswordTokenFailure,
  metamaskLoginStart,
  metamaskLoginSuccess,
  metamaskLoginFailure,
  connectMetamaskStart,
  connectMetamaskSuccess,
  connectMetamaskFailure,
  resetError,
  resetSuccess,
  walletConnectLoginStart,
  walletConnectLoginSuccess,
  walletConnectLoginFailure,
  refreshTokenExpired,
  refreshTokenExpire,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
