import React, { useEffect } from "react";

import { Grid, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import useStyles from "./Lobby.styles";
import { getGamesStart } from "../../../redux-saga/redux/casino/lobby/casinoGames";
import { formatCategoryName } from "../../../utils/helper";
import Game from "../Game";
import { CASINO_LOBBY_GAMES_LIMIT } from "../../../utils/constants";

const Games = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { games, loading } = useSelector((state) => state.casinoGames);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getGamesStart({
        limit: CASINO_LOBBY_GAMES_LIMIT,
        isMobile,
      })
    );
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Grid textAlign="center">
          <CircularProgress className={classes.categoryLoader} size={50} />
        </Grid>
      ) : games?.length > 0 ? (
        games.map((gameType) =>
          gameType?.MasterCasinoGames?.length > 0 ? (
            <Grid key={gameType.name} className={classes.contentWrap}>
              <Grid className={classes.innerHeading}>
                <Grid className={classes.innerHeadingLeft}>
                  <img src="/images/star-icon.svg" alt="Star Icon" />
                  <Typography variant="h6" gutterBottom>
                    {formatCategoryName(gameType.name)}
                  </Typography>
                </Grid>
                {gameType?.MasterCasinoGames?.length >=
                  CASINO_LOBBY_GAMES_LIMIT && (
                  <Link
                    to={`/view-all/false/${gameType.masterGameSubCategoryId}/${gameType.name}`}
                    className={classes.btnPrimary}
                  >
                    {t("COMMON.VIEW_ALL")}
                  </Link>
                )}
              </Grid>
              <Grid container spacing={2} className="gamesOuter">
                {gameType.MasterCasinoGames.map((game, index) => (
                  <Game
                    categoryName={formatCategoryName(gameType.name)}
                    providerName={game.MasterCasinoProvider?.name}
                    key={`${game.masterCasinoGameId}${index}`}
                    thumbnailUrl={game.thumbnailUrl}
                    name={game.name}
                    gameId={game.masterCasinoGameId}
                  />
                ))}
              </Grid>
            </Grid>
          ) : null
        )
      ) : (
        <h1 className={classes.noGamesMsg}>No Games Found</h1>
      )}
    </>
  );
};

export default Games;
