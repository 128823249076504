import { makeStyles } from "@mui/styles";
// import { textAlign } from "@mui/system";

export default makeStyles((theme) => ({
  sidebarWrap: {
    flex: "0 0 210px",
    position: "fixed",
    top: "68px",
    left: "0",
    zIndex: "2",
    height: "100%",
    flexFlow: "column",
    [theme.breakpoints.down("md")]: {
      top: "60px",
    },

    // height: "100%",
    "& .MuiToolbar-gutters": {
      padding: "0",
      height: "100%",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "36px",
    },
    "& .MuiListItem-button": {
      "&.active": {
        borderRadius: "30px",
        // background: "#7458DC",
        margin: "5px 0",
      },
    },
  },
  mainWrap: {
    display: "flex",
  },
  closeSidebarWrap: {
    width: "50px",
    flex: "0 0 50px",
    position: "fixed",
    top: "60px",
    left: "0",
    zIndex: "1",
    height: "100%",
    "& .MuiToolbar-gutters": {
      padding: "0",
      height: "100%",
    },
    "& .MuiListItemIcon-root": {
      justifyContent: "center",
      minWidth: "36px",
    },
    "& .MuiListItem-button": {
      padding: "15px 10px !important",
    },
  },
  menuSliderContainer: {
    width: "250px",
    background: "#050925",
    height: "100%",
    // transition:"all 600ms ease-in-out",
    paddingTop: "30px",

    // padding:"0 10px",
  },
  menuSliderContainerClose: {
    background: "#050925",
    height: "100%",
    paddingTop: "20px",
    // transition:"all 600ms ease-in-out",
  },
  listItem: {
    width: "100%",
    color: "#fff",
    textDecoration: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    fontWeight: "500",
    "&:hover": {
      background: "#7d7de2",
      color: "#fff",
      "& a": {
        color: "#fff",
        background: "transparent",
      },
    },
    "& .MuiBox-root": {
      display: "none",
      "+ span": {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        "& button": {
          display: "flex",
          color: "#fff",
        },
      },
    },
  },
  languageListItem: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      color: "#fff",
      textDecoration: "none",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      textTransform: "uppercase",
      fontWeight: "500",
      "&:hover": {
        background: "#7d7de2",
        color: "#fff",
      },
      "& .MuiBox-root": {
        display: "flex",
        "& button": {
          display: "flex",
          color: "#fff",
        },
      },
    },
  },
  activeListItem: {
    color: "#fff",
    background: "#7d7de2",
    textDecoration: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  sidebarTabsWrap: {
    background: "#1E2024",
    padding: "20px 5px 10px",
  },
  sidebarTabs: {
    background: "#26282D",
    padding: "5px",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButtonBase-root": {
      padding: "8px 30px",
      borderRadius: "30px",
      "&.active": {
        background: " #7458DC",
      },
    },
  },
  sidebarToggleIcon: {
    fontSize: "20px",
    position: "absolute",
    left: "209px",
    top: "-24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    borderRadius: "0px 13px 13px 0px",
    width: "auto",
    padding: "5px",
    cursor: "pointer",
  },
  sidebarToggleIconClose: {
    left: "10px",
    paddingBottom: "5px",
    textAlign: "center",
    cursor: "pointer",
    padding: "0 5px",
  },
  sidebarDollarIcon: {
    paddingLeft: "6px",
  },
  disableList: {
    background: "#424582",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    fontWeight: "500",
    "& img": {
      transform: "rotate(180deg)",
    },
  },
  dividerStyle: {
    backgroundColor: "#33353a",
    width: "80%",
    marginLeft: "1vw",
  },
  languageText: {
    fontSize: "small",
    color: "#99a4b1",
    marginLeft: "6px",
    textAlign: "center",
  },
  languageSelectOpen: {
    background: "#26282d",
    borderRadius: "12px",
    width: "80%",
    margin: "1vw",
    padding: "0.5vw",
    color: "#fff",
  },
  languageSelectClose: {
    background: "#26282d",
    borderRadius: "12px",
    width: "100%",
    padding: "0.5vw",
    color: "#fff",
  },
  languageMenu: {
    color: "#fff",
    "& li": {
      "& img": {
        marginRight: "0.5rem",
      },
      "&:hover": {
        background: "#7d7de2",
        color: "#fff",
      },
    },
  },
}));
