/**
 * To check the email validation
 * @function validateEmail
 *
 * @param {string} email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  const validate = String(email).match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return !!validate;
};

/**
 * To check if two passwords match
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns
 */
export const isMatchPassword = (password, confirmPassword) =>
  password === confirmPassword;

/**
 * To check if password is alphanumeric
 * and between 4 and 8 characters
 *
 * @param {string} password
 * @returns {boolean}
 */
export const validatePassword = (password) => {
  const validate = String(password).match(
    /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/
  );
  return !!validate;
};

/**
 * To check Phone Number Validation
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validatePhoneNumber = (string) => {
  const validate = String(string).match(
    /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im
  );
  return !!validate;
};

/**
 * To check string contain only numbers
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validateNumber = (string) => {
  const validate = String(string).match(/^[0-9]*$/);
  return !!validate;
};

/**
 * To check string contain only letters
 * Vaidates for a-z and A-Z and white space
 *
 * @param {string} string
 * @returns {boolean}
 */
export const validateLetters = (string) => {
  const validate = String(string).match(/^[a-zA-Z ]{2,30}$/);
  return !!validate;
};

/**
 * To format Category name
 * Returned value is Capitalized First alphabet with '-' character removed if contains.
 *
 * @param {string}
 * @returns {string}
 */

export const formatCategoryName = (name) => {
  if (name) {
    let tempName = name.replace("-", " ");
    tempName = tempName.charAt(0).toUpperCase() + tempName.slice(1);
    return tempName;
  }
  return "";
};

/**
 * To format wallet address according to the show condition
 * Returned value is concatenation of First 5 alphabet and '...'
 *
 * @param {boolean, string}
 * @returns {string}
 */
export const formatWalletAddress = (show, walletAddress) => {
  let address;
  if (show && walletAddress) {
    return walletAddress;
  }
  if (walletAddress) {
    address = walletAddress.slice(0, 5);
    address += "...";
  }
  if (!walletAddress) {
    address = "Not Found";
  }
  return address;
};

/**
 * To check if a string is numeric or not
 *
 * @param {string} str
 * @returns {boolean}
 */
export const isNumeric = (str) => {
  if (typeof str !== "string") return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
};
