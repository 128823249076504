import React from "react";

import { Button, Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useStyles from "../Lobby/Lobby.styles";

const Game = ({
  thumbnailUrl,
  name,
  gameId,
  providerName = null,
  categoryName = null,
  setShowSearch,
}) => {
  const { metamaskLoggedIn, isLoggedIn } = useSelector(
    (state) => state.authentication
  );
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleGameLaunch = () => {
    if (!(metamaskLoggedIn || isLoggedIn)) {
      toast.error(t("GAME.GAME_LOGIN_MSG"), {
        id: "GAME_LOGIN_MSG",
      });
      return;
    }
    navigate("/game", { state: gameId });
    setShowSearch && setShowSearch((prev) => !prev);
  };

  return (
    <Grid item sm={4} md={2} xs={12}>
      <Grid className={classes.casinoCard} onClick={() => handleGameLaunch()}>
        <Box className="imgBox">
          <img
            src={thumbnailUrl || "/images/casino-img.png"}
            alt="Casino Card"
          />
          <Box className="squareBox"></Box>
          <Box className="playBtnBox">
            <Button type="button" className="playBtn">
              <svg viewBox="0 0 384 512">
                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
              </svg>
              <span className="text">Play Now</span>
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid className={classes.casinoOverlay}>
        <Typography>{name || t("GAME.GAME")}</Typography>
        <Link className={classes.infoBtn}>
          <img src="/images/info-icon.svg" alt="Info Icon" />
          {/* <Grid className={classes.customInfoModal}>
            <Grid className={classes.customInfoCard}>
              <span>Game Name</span>
              <Typography>Hot Road Racer </Typography>
            </Grid>
          </Grid> */}
          <Grid className={classes.customInfoModal}>
            <Grid className={classes.customInfoCard}>
              <span>{t("COMMON.NAME")}</span>
              <Typography>{name}</Typography>
            </Grid>
            {categoryName ? (
              <Grid className={classes.customInfoCard}>
                <span>{t("COMMON.CATEGORY")}</span>
                <Typography>{categoryName}</Typography>
              </Grid>
            ) : null}
            {providerName ? (
              <Grid className={classes.customInfoCard}>
                <span>{t("COMMON.PROVIDER")}</span>
                <Typography>{providerName}</Typography>
              </Grid>
            ) : null}
            {/* <Grid className={classes.customInfoCard}>
              <span>Game Name</span>
              <Typography>Hot Road Racer </Typography>
            </Grid> */}
          </Grid>
        </Link>
      </Grid>
    </Grid>
  );
};

export default Game;
