import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  sidebarWrap: {
    background: " #091251",
    width: "324px",
    borderRadius: "10px",
    flex: "0 0 324px",
    transition: "all 600ms ease-in-out",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  accountWrap: {
    // display: "flex",
    // background:"url('../images/account-star.png')",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "10px 100px",
    minHeight: "calc(100vh - 470px)",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "10px 15px",
      minHeight: "calc(100vh - 390px)",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 266px)",
    },
  },
  accountRightWrap: {
    width: "100%",
    marginLeft: "10px",
    display: "flex",
    paddingTop: "100px",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      overflowX: "auto",
      justifyContent: "flex-start",
    },
    sidebarListing: {
      padding: "0",
    },
  },
  sidebarUserIcon: {
    textAlign: "center",
    paddingTop: "20px",
  },
  sidebarUserName: {
    textAlign: "center",
    marginTop: "10px",
    paddingBottom: "20px",
    marginBottom: "20px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
  },
  sidebarListingWrap: {
    display: "flex",
  },
  sidebarListing: {
    padding: "15px 0px",
    "& a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#7D7DE2",
      fontWeight: "500",
      padding: "8px 30px",
      fontSize: "14px",
      borderRadius: "30px",
      "&:hover": {
        color: "#FFF",
        background: "rgba(0, 0, 0, 0.5)",
      },
      [theme.breakpoints.down("md")]: {
        whiteSpace: "nowrap",
        padding: "8px 10px",
      },
    },
    "& span": {
      marginRight: "20px",
      display: "flex",
    },
  },
}));
