import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import { useAccount } from "wagmi";

import useStyles from "./MyProfile.styles";
import { formatWalletAddress } from "../../../utils/helper";

const MyProfile = () => {
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { userDetails } = useSelector((state) => state.myProfile);
  const { sectionData } = useSelector((state) => state.section);
  const { t } = useTranslation();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  // const { connector: activeConnector } = useAccount();

  return (
    <>
      {isLoggedIn ? (
        <div className={classes.accountRight}>
          <Grid className={classes.myprofileBottom}>
            <h4 className="profile-heading"> {t("MYPROFILE.MY_PROFILE")}</h4>
            <Grid container className={classes.myprofileForm}>
              <Grid className="upload-button">
                <Link to="/user/updateprofile">update profile</Link>
              </Grid>
              <Typography variant="h6">
                {/* {t("MYPROFILE.CURRENT_CONNECTOR_LOGIN_MSG") +
                  " " +
                  activeConnector?.name || ""} */}
                {sectionData.map((section) => (
                  <Grid key={section.id}> {Parser(section?.content)}</Grid>
                ))}
              </Typography>
              <br />
              <Typography variant="h7" gutterBottom>
                <b>{t("MYPROFILE.WALLET_ADDRESS")} : </b>
                {formatWalletAddress(show, userDetails?.tokenAddress)}
              </Typography>
              <br />
              <Button
                size="small"
                variant="contained"
                onClick={() => setShow((current) => !current)}
                className={classes.metamaskBtn}
              >
                {show ? t("COMMON.HIDE") : t("COMMON.SHOW")}
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default MyProfile;
