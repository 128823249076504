import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  convertLoading: false,
  generateLoading: false,
  withdrawLoading: false,
  withdrawSuccess: false,
  success: false,
  currencyList: [],
  generateAddress: null,
  coinList: [],
  convertedBalance: null,
  selectedCurrency: null,
  selectedCoin: null,
  error: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    getCurrencyListStart: (state, action) => {
      state.loading = true;
      state.currencyList = [];
      state.selectedCurrency = null;
    },
    getCurrencyListSuccess: (state, action) => {
      state.loading = false;
      state.currencyList = action.payload.data;
    },
    getCurrencyListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getCoinsStart: (state, action) => {
      state.loading = true;
      state.coinList = [];
      state.currencyList = [];
    },
    getCoinsSuccess: (state, action) => {
      state.loading = false;
      state.coinList = action.payload.data;
    },
    getCoinsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    generateAddressStart: (state, action) => {
      state.generateLoading = true;
    },
    generateAddressSuccess: (state, action) => {
      state.generateLoading = false;
      state.generateAddress = action.payload?.address;
    },
    generateAddressFailure: (state, action) => {
      state.generateLoading = false;
      state.error = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
      state.generateAddress = null;
    },
    setSelectedCoin: (state, action) => {
      state.selectedCoin = action.payload;
      state.generateAddress = null;
    },
    getConvertedBalanceStart: (state, action) => {
      state.convertLoading = true;
    },
    getConvertedBalanceSuccess: (state, action) => {
      state.convertLoading = false;
      state.convertedBalance = action.payload?.balance;
    },
    getConvertedBalanceFailure: (state, action) => {
      state.convertLoading = false;
      state.error = action.payload;
    },
    withdrawalRequestStart: (state, action) => {
      state.withdrawLoading = true;
      state.withdrawSuccess = false;
    },
    withdrawalRequestSuccess: (state, action) => {
      state.withdrawLoading = false;
      state.convertedBalance = null;
      state.withdrawSuccess = true;
    },
    withdrawalRequestFailure: (state, action) => {
      state.withdrawLoading = false;
      state.error = action.payload;
    },
    resetConvertedBalance: (state, action) => {
      state.convertedBalance = null;
    },
    resetPaymentState: (state) => {
      state.coinList = initialState.coinList;
      state.currencyList = initialState.currencyList;
      state.generateAddress = initialState.generateAddress;
      state.convertedBalance = initialState.convertedBalance;
      state.selectedCurrency = initialState.selectedCurrency;
      state.selectedCoin = initialState.selectedCoin;
    },
  },
});

export const {
  getCurrencyListStart,
  getCurrencyListSuccess,
  getCurrencyListFailure,
  generateAddressStart,
  generateAddressSuccess,
  generateAddressFailure,
  getCoinsStart,
  getCoinsSuccess,
  getCoinsFailure,
  setSelectedCoin,
  setSelectedCurrency,
  getConvertedBalanceStart,
  getConvertedBalanceSuccess,
  getConvertedBalanceFailure,
  withdrawalRequestStart,
  withdrawalRequestSuccess,
  withdrawalRequestFailure,
  resetConvertedBalance,
  resetPaymentState,
} = paymentSlice.actions;

export default paymentSlice.reducer;
