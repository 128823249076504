import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  iframeCirculerLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 170px)",
  },
  iframeWrap: {
    position: "relative",
    margin: "80px 0 60px",
    minHeight: "calc(100vh - 240px)",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginRight: "10px",
      margin: "90px 0 0",
      minHeight: "calc(100vh - 300px)",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "70px",
      marginBottom: "10px",
    },
    "& .MuiGrid-container": {
      padding: "0 15px",
      maxWidth: "1350px",
      [theme.breakpoints.up("xl")]: {
        maxWidth: "1450px",
      },
    },
  },
  gameWrap: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
    minWidth: "100%",
  },
  backBtn: {
    position: "absolute",
    left: "10px",
    top: "0px",
    zIndex: "2",
    padding: "5px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "14px",
    margin: "10px 0",
    "& img": {
      marginRight: "5px",
      transform: "rotate(180deg)",
      filter: "invert(1)",
    },
    [theme.breakpoints.down("md")]: {
      background: "transparent",
      top: "0",
      color: "#fff",
      padding: "0",
    },
  },
  iframeContent: {
    height: "600px",
    width: "100%",
    borderRadius: "6px 0 6px 6px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    overflow: "hidden",
    position: "relative",
    paddingRight: "30px",
    [theme.breakpoints.up("xl")]: {
      height: "570px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "500px",
      // maxWidth: "1050px",
    },

    "& iframe": {
      borderRadius: "5px",
      background: "#050925",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: "0",
    },
  },
  iframeBtnWrap: {
    position: "absolute",
    display: "flex",
    flexFlow: "column",
    right: "0px",
    top: "0",
    "& .MuiButtonBase-root": {
      padding: "10px 5px",
      background: "#050925",
      borderRadius: "0",
      minWidth: "30px",
      "& img": {
        width: "12px",
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  iframeGameCard: {
    cursor: "pointer",
    borderRadius: "10px",
    overflow: "hidden",
    height: "180px",
    marginBottom: "10px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  iframeGameWrap: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexFlow: "row",
    },
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
  },
}));
