import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { DialogContent, Grid, TextField } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";

import Modal from "../../../HOC/modal/Modal";
import useStyles from "./SearchGames.styles";
import SearchModalContent from "./SearchModalContent";
import { resetSearchGames } from "../../../redux-saga/redux/casino/lobby/casinoGames";

const SearchGames = () => {
  const classes = useStyles();
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      {/* <Button
        className={classes.btnPrimary}
        onClick={() => setShowSearch((prev) => !prev)}
        startIcon={<SearchIcon />}
      >
        Search games
      </Button> */}
      <Grid
        onClick={() => {
          dispatch(resetSearchGames());
          setShowSearch((prev) => !prev);
        }}
      >
        <TextField
          id="outlined-basic"
          variant="outlined"
          placeholder="Search"
          onChange={() => {
            dispatch(resetSearchGames());
            setShowSearch((prev) => !prev);
          }}
          value="Search"
        />

        <img src="/images/search-icon.svg" alt="Search" />
      </Grid>

      <Modal
        isShowing={showSearch}
        className={classes.dialogcontainer}
        content={
          <DialogContent>
            <SearchModalContent setShowSearch={setShowSearch} />
          </DialogContent>
        }
        hide={() => {
          setShowSearch((prev) => !prev);
          dispatch(resetSearchGames());
        }}
      />
    </>
  );
};

export default SearchGames;
