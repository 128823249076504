import React, { useEffect } from "react";

import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { I18nextProvider } from "react-i18next";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import { WagmiConfig, createClient, configureChains, mainnet } from "wagmi"
// import { infuraProvider } from 'wagmi/providers/infura'
// import { publicProvider } from 'wagmi/providers/public'
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";

import AppRoutes from "./App.routes";
import getDarkTheme from "./theme/dark";
import i18n from "./i18n";
import { currentOrientation } from "./redux-saga/redux/settings";

import "./App.css";

// const { provider, webSocketProvider } = configureChains(
//   [mainnet],
//   [infuraProvider({
//     apiKey: 'f4eeeb344b1e40208acf0c9e9db2b8a7',
//     priority: 0,
//   })],
//   publicProvider({ priority: 1 }),
// )

// const client = createClient({
//   autoConnect: true,
//   provider,
//   webSocketProvider,
// })
const chains = [arbitrum, mainnet, polygon];
const projectId = "64683e8f2e51a1d403f6c5f3b8746210";

// Wagmi client
const { provider } = configureChains(chains, [
  w3mProvider({ projectId: projectId }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: projectId, version: 2, chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  let selectTheme = getDarkTheme();
  const theme = createTheme(selectTheme);
  const { sidebarOpen } = useSelector((state) => state.settings);
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  const dispatch = useDispatch();
  useEffect(() => {
    var mediaQueryRule = window.matchMedia("(orientation: portrait)");
    mediaQueryRule.onchange = function () {
      if (window.matchMedia("(orientation: portrait)").matches) {
        dispatch(currentOrientation("PORTRAIT"));
      }
      if (window.matchMedia("(orientation: landscape)").matches) {
        dispatch(currentOrientation("LANSCAPE"));
      }
    };
  }, [dispatch]);
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <CssBaseline />
              <AppRoutes
                sidebarOpen={sidebarOpen}
                isGameStarted={isGameStarted}
              />
              <Toaster position="top-right" />
            </I18nextProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
