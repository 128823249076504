import { all } from "redux-saga/effects";

import authenticationWatcher from "./auth/authentication";
import userProfileWatcher from "./profile/myProfile";
import transactionsWatcher from "./profile/transactions";
import resetPasswordWatcher from "./auth/resetPassword";
import casinoLobbyWatcher from "./casino/lobby/casinoLobby";
import casinoGamesWatcher from "./casino/lobby/casinoGames";
import cmsWatcher from "./cms";
import walletWatcher from "../saga/profile/wallet";
import paymentWatcher from "./payment";
import advertisementWatcher from "./advertisement";
import sectionWatcher from "./section";

export default function* rootSaga() {
  yield all([
    authenticationWatcher(),
    userProfileWatcher(),
    transactionsWatcher(),
    paymentWatcher(),
    resetPasswordWatcher(),
    casinoLobbyWatcher(),
    casinoGamesWatcher(),
    cmsWatcher(),
    walletWatcher(),
    advertisementWatcher(),
    sectionWatcher(),
  ]);
}
