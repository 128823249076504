import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  accordiondetails: {
    "& .MuiChip-colorPrimary": {
      backgroundColor: "#424582",
    },
  },
  chipcontainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
  },
}));
