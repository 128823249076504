import React, { useEffect } from "react";

import { Button, CircularProgress, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import useStyles from "./Header.styles";
import { startPoll } from "../../redux-saga/redux/profile/myProfile";
import { getUserProfileDetailsStart } from "../../redux-saga/redux/profile/myProfile";
import { resetSuccess } from "../../redux-saga/redux/auth/authentication";
import session from "../../utils/session";

const Wallet = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const { metamaskLoggedIn, isLoggedIn, success, metamaskSuccess } =
    useSelector((state) => state.authentication);
  const { walletAmount, walletLoading } = useSelector(
    (state) => state.myProfile
  );

  useEffect(() => {
    dispatch(getUserProfileDetailsStart({ walletLoading: true }));
    if ((metamaskLoggedIn && metamaskSuccess) || (isLoggedIn && success)) {
      const user = session.getSessionCred;
      dispatch(startPoll(user.userId));
      dispatch(resetSuccess());
    }
  }, [dispatch, isLoggedIn, metamaskLoggedIn, metamaskSuccess, success]);

  return (
    <Grid className={classes.balanceBtnWrap}>
      <Button
        onClick={() => navigate("/user/deposit")}
        className={classes.btnPrimary}
      >
        <span>
          <img src="/images/wallet.svg" alt="Wallet icon" />
        </span>
        {walletLoading ? (
          <CircularProgress size={25} />
        ) : (
          // eslint-disable-line no-use-before-define
          `$ ${0.0 || walletAmount}`
        )}
      </Button>
    </Grid>
  );
};

export default Wallet;
