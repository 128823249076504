import React, { useState, useEffect, useRef } from "react";

import {
  Grid,
  Button,
  TextField,
  // IconButton,
  // MenuItem,
  CircularProgress,
  // Badge,
  // Avatar,
} from "@mui/material";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { Edit } from "@mui/icons-material";

import useStyles from "./MyProfile.styles";
// import countries from "../../Header/SignUp/countries.json";
import {
  // validateEmail,
  // validatePhoneNumber,
  // validateNumber,
  validateLetters,
} from "../../../utils/helper";
import {
  getUserProfileDetailsStart,
  updateUserProfileStart,
} from "../../../redux-saga/redux/profile/myProfile";
// import userProfileService from "../../../services/user";
import // ACCEPTED_IMAGE_FORMATS,
// MAX_IMAGE_FILE_SIZE,
"../../../utils/constants";
// import MetamaskButton from "./MetamaskButton";
import WalletAddress from "./WalletAddress";

const UserProfile = () => {
  const { loading, userDetails } = useSelector((state) => state.myProfile);
  const offset = useRef(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [avatar, setAvatar] = useState("");
  // const [selectedImage, setSelectedImage] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [userFields, setUserFields] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    // phone: "",
    // address: "",
    // zipCode: "",
    // city: "",
    // countryCode: "",
  });

  useEffect(() => {
    setUserFields({
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      userName: userDetails?.userName || "",
      email: userDetails?.email || "",
      // phone: userDetails?.phone || "",
      // address: userDetails?.address || "",
      // zipCode: userDetails?.zipCode || "",
      // city: userDetails?.city || "",
      // countryCode: userDetails?.countryCode || "",
    });
    // setAvatar((current) => userDetails?.profileImage || current);
  }, [userDetails]);

  useEffect(() => {
    if (offset.current === 0) {
      dispatch(getUserProfileDetailsStart());
      offset.current = 1;
    }
  }, [dispatch]);

  const handleEditProfile = () => {
    const validation = [];
    const {
      firstName,
      lastName,
      userName,
      email,
      // phone,
      // address,
      // zipCode,
      // countryCode,
      // city,
    } = userFields;

    if (firstName.length === 0 || !validateLetters(firstName)) {
      validation.push(t("MYPROFILE.FIRSTNAME_VALIDATION"));
    }
    if (lastName.length === 0 || !validateLetters(lastName)) {
      validation.push(t("MYPROFILE.LASTNAME_VALIDATION"));
    }
    if (userName.length === 0 || !validateLetters(userName)) {
      validation.push(t("MYPROFILE.userName_VALIDATION"));
    }
    // if (address?.length === 0) {
    //   validation.push(t("MYPROFILE.ADDRESS_VALIDATION"));
    // }
    // if (countryCode?.length === 0) {
    //   validation.push(t("MYPROFILE.COUNTRY_VALIDATION"));
    // }
    // if (!validateEmail(email)) {
    //   validation.push(t("COMMON.EMAIL_VALIDATION"));
    // }
    // if (!validatePhoneNumber(phone)) {
    //   validation.push(t("MYPROFILE.PHONE_NUMBER_VALIDATION"));
    // }
    // if (!validateNumber(zipCode)) {
    //   validation.push(t("MYPROFILE.ZIP_CODE_VALIDATION"));
    // }

    if (validation.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, {
          id: message,
        });
      });
      return;
    }

    dispatch(
      updateUserProfileStart({
        firstName,
        lastName,
        userName,
        // countryCode,
        email,
        // phone,
        // city,
        // zipCode,
        // address,
      })
    );
    setEditProfile((current) => !current);
  };

  // const handleUserProfileAvatar = async () => {
  //   if (selectedImage === null) {
  //     toast.error(t("MYPROFILE.IMAGE_VALIDATION"), {
  //       id: "IMAGE_VALIDATION",
  //     });
  //     return;
  //   }
  // const fd = new FormData();
  // fd.append("image", selectedImage);
  // const { data, error } = await userProfileService.updateUserProfileAvatar(
  //   fd
  // );

  //   if (!error) {
  //     toast.success(t("MYPROFILE.IMAGE_UPDATE_SUCCESS"), {
  //       id: "IMAGE_UPDATE_SUCCESS",
  //     });
  //     setSelectedImage(null);
  //     setAvatar(data?.profileImage);
  //   }
  //   if (error) {
  //     toast.error(t("MYPROFILE.ERROR"), {
  //       id: "MYPROFILE.ERROR",
  //     });
  //   }
  //   setSelectedImage(null);
  // };

  // const handleFileInputChange = (event) => {
  //   event.preventDefault();
  //   const file = event.target.files && event.target.files[0];

  //   if (file?.size > MAX_IMAGE_FILE_SIZE) {
  //     toast.error(t("MYPROFILE.MAX_FILE_SIZE_ERROR"), {
  //       id: "MAX_FILE_SIZE_ERROR",
  //     });
  //     return;
  //   }
  //   setSelectedImage(file);
  // };

  // const makeAvatarUrl = () => {
  //   if (selectedImage) {
  //     return URL.createObjectURL(selectedImage);
  //   }
  //   return "/images/user_icon.svg";
  // };

  const handleChange = (e) => {
    if (e.target?.name === "countryCode") {
      setUserFields((current) => ({
        ...current,
        countryCode: e.target.value,
      }));
      return;
    }
    setUserFields((current) => ({
      ...current,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        {/* <Typography variant="h4" gutterBottom>
          {t("MYPROFILE.MY_PROFILE")}
        </Typography> */}
        <h4 className="profile-heading">Update Profile </h4>
        {!loading ? (
          <>
            {/* <MetamaskButton /> */}
            <WalletAddress />
          </>
        ) : null}
        <Grid className={classes.myprofileForm}>
          <Grid className={classes.profileUpdateRight}>
            {/* <Grid className={classes.profileUpdateIcon}>
              <Badge
                overlap="circular"
                badgeContent={
                  <IconButton variant="contained" component="label">
                    <input
                      onChange={handleFileInputChange}
                      type="file"
                      hidden
                      accept={ACCEPTED_IMAGE_FORMATS.join(",")}
                    />
                    <Edit />
                  </IconButton>
                }
              >
                {selectedImage ? (
                  <Avatar
                    sx={{ height: 50, width: 50 }}
                    src={makeAvatarUrl()}
                  />
                ) : (
                  <Avatar
                    src={avatar || "/images/user_icon.svg"}
                    alt="User Icon"
                  />
                )}
              </Badge>
            </Grid> */}
            {/* {selectedImage ? (
              <Button
                onClick={() => handleUserProfileAvatar()}
                className={classes.btnPrimary}
              >
                {t("MYPROFILE.UPDATE_PROFILE")}
              </Button>
            ) : null} */}
            {/* <Grid>
              {!editProfile && !selectedImage ? (
                <Button
                  onClick={() => setEditProfile((current) => !current)}
                  className={classes.btnPrimary}
                >
                  {t("MYPROFILE.EDIT_PROFILE")}
                </Button>
              ) : null}
            </Grid> */}
          </Grid>
          <Grid className={classes.signupFromFname}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={editProfile}
                    id="firstName"
                    label={t("COMMON.FIRST_NAME")}
                    variant="outlined"
                    value={userFields.firstName}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={editProfile}
                    id="lastName"
                    label={t("COMMON.LAST_NAME")}
                    variant="outlined"
                    value={userFields.lastName}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={editProfile}
                    id="userName"
                    label={t("COMMON.USERNAME")}
                    variant="outlined"
                    value={userFields.userName}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={editProfile}
                    id="email"
                    label={t("COMMON.EMAIL_ADDRESS")}
                    variant="outlined"
                    value={userFields.email}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={!editProfile}
                    id="phone"
                    label={t("MYPROFILE.PHONE_NUMBER")}
                    variant="outlined"
                    value={userFields.phone}
                    onChange={handleChange}
                  />
                </div>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={!editProfile}
                    id="address"
                    label={t("MYPROFILE.ADDRESS")}
                    variant="outlined"
                    value={userFields.address}
                    onChange={handleChange}
                  />
                </div>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={!editProfile}
                    id="zipCode"
                    label={t("MYPROFILE.ZIP_CODE")}
                    variant="outlined"
                    value={userFields.zipCode}
                    onChange={handleChange}
                  />
                </div>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={!editProfile}
                    id="city"
                    label={t("MYPROFILE.CITY")}
                    variant="outlined"
                    value={userFields.city}
                    onChange={handleChange}
                  />
                </div>
              </Grid> */}
              {/* <Grid item xs={12} md={6}>
                <div className={classes.formGroup}>
                  <TextField
                    disabled={!editProfile}
                    name="countryCode"
                    id="countryCode"
                    select
                    label={t("COMMON.COUNTRY_REGION")}
                    variant="outlined"
                    value={userFields.countryCode}
                    onChange={handleChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid> */}
              <Grid item sm={12}></Grid>
            </Grid>
          </Grid>
          <Grid className={classes.authBtnWrap}>
            {!editProfile ? (
              <Button
                disabled={loading}
                onClick={() => handleEditProfile()}
                className={classes.btnPrimary}
              >
                {loading ? <CircularProgress /> : t("COMMON.SAVE_CHANGES")}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserProfile;
