import React, { useState } from "react";

import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StartIcon from "@mui/icons-material/Start";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

import { toggleSidebar } from "../../redux-saga/redux/settings";
import useStyles from "./PageLayout.styles";
import { sidebarListItems } from "./constants";
import Languages from "../Header/Languages.list.json";

const PageLayout = () => {
  const classes = useStyles();
  const { sidebarOpen } = useSelector((state) => state.settings);
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(
    sidebarListItems[0].listText
  );

  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const openLanguage = Boolean(anchorElLanguage);
  const toggleSlider = () => {
    if (sidebarOpen) {
      dispatch(toggleSidebar(false));
    } else {
      dispatch(toggleSidebar(true));
    }
  };
  const handleCloseLanguage = (event, language) => {
    i18n.changeLanguage(language);
    setAnchorElLanguage(null);
  };

  const sideList = () => (
    <Box
      className={
        sidebarOpen
          ? classes.menuSliderContainer
          : classes.menuSliderContainerClose
      }
      component="div"
    >
      <List>
        <ListItem
          onClick={toggleSlider}
          className={
            sidebarOpen
              ? classes.sidebarToggleIcon
              : classes.sidebarToggleIconClose
          }
        >
          {sidebarOpen ? <ArrowBackIcon /> : <StartIcon />}
        </ListItem>
        {sidebarOpen && (
          <ListItem className={classes.disableList}>
            Casino
            <div className={classes.listIcon}>
              <img src="/images/rocket.png" alt="Rocket" />
            </div>
          </ListItem>
        )}
        <ListItem className={classes.sidebarDollarIcon}>
          {!sidebarOpen && <img src="/images/dollar-ico.svg" alt="Dollar" />}
        </ListItem>

        {sidebarOpen &&
          sidebarListItems.map((listItem, index) => (
            <ListItem
              className={
                selectedItem === listItem.listText
                  ? classes.activeListItem
                  : classes.listItem
              }
              key={index}
              onClick={() => setSelectedItem(listItem.listText)}
            >
              {/* <ListItemIcon
                  className={classes.listItem}
                  onClick={() => handleNavigate(listItem.to)}
                >
                  <img alt={listItem.alt} src={listItem.listIcon} />
                </ListItemIcon> */}
              <Link to={listItem.to} className={classes.listItem}>
                <ListItemText primary={listItem.listText} />
              </Link>
            </ListItem>
          ))}

        {sidebarOpen && (
          <ListItem className={classes.languageListItem} button>
            <Box component="div">
              <Button
                id="language-button"
                aria-controls={openLanguage ? "language-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openLanguage ? "true" : undefined}
                onClick={(event) => setAnchorElLanguage(event.currentTarget)}
                startIcon={
                  <img
                    align="right"
                    src={`/images/${
                      Languages?.[i18n.language?.toUpperCase() || "EN"]?.flag
                    }.svg`}
                    alt="country flag"
                  />
                }
                endIcon={
                  openLanguage ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                }
              >
                {Languages?.[i18n.language?.toUpperCase() || "EN"]?.name}
              </Button>
            </Box>
            <Menu
              id="language-menu"
              anchorEl={anchorElLanguage}
              open={openLanguage}
              onClose={() => setAnchorElLanguage(null)}
              className={classes.languageMenu}
              MenuListProps={{
                "aria-labelledby": "language-button",
              }}
            >
              <MenuItem onClick={(event) => handleCloseLanguage(event, "en")}>
                <img
                  src="/images/united-kingdom-flag-icon.svg"
                  alt="country flag"
                />
                English
              </MenuItem>
              <MenuItem onClick={(event) => handleCloseLanguage(event, "es")}>
                <img
                  src="/images/argentina-flag-icon.svg"
                  align="left"
                  alt="country flag"
                />
                Español
              </MenuItem>
            </Menu>
          </ListItem>
        )}
      </List>
    </Box>
  );

  if (isGameStarted && isMobile) {
    return;
  }
  return (
    <Grid className={classes.mainWrap}>
      <Box
        className={sidebarOpen ? classes.sidebarWrap : classes.closeSidebarWrap}
      >
        {sideList()}
      </Box>
    </Grid>
  );
};

export default PageLayout;
