import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { isMobile } from "react-device-detect";

import Deposit from "./components/User/Deposit";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MyProfile from "./components/User/MyProfile";
import NotASubscriber from "./components/NotASubscriber";
import Sidebar from "./components/Sidebar";
import Lobby from "./container/Casino/Lobby";
import Transaction from "./components/User/Transaction";
import ResetPassword from "./components/User/ResetPassword";
import Withdraw from "./components/User/Withdraw";
import BetHistory from "./components/User/BetHistory";
import ForgotPassword from "./components/ForgotPassword";
import VerifyEmail from "./components/VerifyEmail";
import Bonus from "./components/User/Bonus";
import Referral from "./components/User/Referral";
import Promotions from "./components/Promotions";
import Livecasino from "./components/Livecasino";
import SportsBet from "./components/SportsBet";
import Toast from "./container/Toast";
import ViewAll from "./container/Casino/ViewAll";
// import IframeGame from "./container/Casino/IframeGame";
import Privacy from "./components/Footer/Privacy";
import Terms from "./components/Footer/Terms";
import Cms from "./components/Footer/Cms";
import PageLayout from "./components/PageLayout/index";
import IframeGame from "./container/Casino/IframeGame";
import UpdateProfile from "./components/User/MyProfile/UpdateUserProfile";

const appRoutes = [
  { path: "/", element: <Lobby /> },
  { path: "/:referralId", element: <Lobby /> },
  { path: "/view-all/:provider/:category/:name", element: <ViewAll /> },
  { path: "/promotions", element: <Promotions /> },
  { path: "/sport-bet", element: <SportsBet /> },
  { path: "/liveCasino", element: <Livecasino /> },
  { path: "/cms/:id/:name", element: <Cms /> },
  { path: "/user/verifyEmail", element: <VerifyEmail /> },
  { path: "/user/forgotPassword", element: <ForgotPassword /> },
  { path: "/privacy-policy", element: <Privacy /> },
  { path: "/terms", element: <Terms /> },
  { path: "*", element: <NotASubscriber /> },
];

const PrivateRoute = () => {
  let isAuthenticated =
    localStorage.getItem("authSession") == null ? false : true;
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export const getApplicationRoutes = (routes) => {
  return routes.map((route) => (
    <Route
      exact
      path={route.path}
      key={route.path}
      element={route.element}
      {...route.props}
    />
  ));
};

const openStyle = { marginLeft: "250px" };
const closeStyle = { marginLeft: "50px" };

export const buildRouter = (routesConfig, sidebarOpen, isGameStarted) => {
  const applicationRoutes = getApplicationRoutes(routesConfig);
  return (
    <Router>
      <PageLayout />
      <Header />
      <div
        style={
          isGameStarted && isMobile
            ? {}
            : sidebarOpen
            ? isMobile
              ? closeStyle
              : openStyle
            : closeStyle
        }
      >
        <Toast />
        <Routes>
          {applicationRoutes}
          <Route element={<PrivateRoute />}>
            <Route path={"/game"} element={<IframeGame />} />
            <Route path="/user" key={"user"} element={<Sidebar />}>
              <Route
                key={"MyProfile"}
                path={"myProfile"}
                element={<MyProfile />}
              />
              <Route
                key={"UpdateProfile"}
                path={"UpdateProfile"}
                element={<UpdateProfile />}
              />
              <Route key={"Deposit"} path={"deposit"} element={<Deposit />} />
              <Route
                key={"Transaction"}
                path={"transaction"}
                element={<Transaction />}
              />
              <Route
                key={"ResetPassword"}
                path={"resetPassword"}
                element={<ResetPassword />}
              />
              <Route
                key={"BetHistory"}
                path={"betHistory"}
                element={<BetHistory />}
              />
              <Route
                key={"referral"}
                path={"referral"}
                element={<Referral />}
              />
              <Route
                key={"Withdraw"}
                path={"withdraw"}
                element={<Withdraw />}
              />
              <Route key={"Bonus"} path={"bonus"} element={<Bonus />} />
              <Route
                key={"Referral"}
                path={"referral"}
                element={<Referral />}
              />
            </Route>
          </Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

const appRouter = ({ sidebarOpen, isGameStarted }) =>
  buildRouter(appRoutes, sidebarOpen, isGameStarted);

export default appRouter;
