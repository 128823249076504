import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  accountRightWrap: {
    width: "100%",
    marginLeft: "5px",
  },
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  myprofileForm: {
    maxWidth: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "4rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginTop: "2rem",
    },
    "& .upload-button": {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      "& a": {
        background:
          "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
        borderRadius: "4px",
        padding: "10px 20px",
        color: "#000",
        fontSize: "14px",
        fontWeight: "600",
        textTransform: "capitalize",
        textDecoration: "none",
      },
    },
    "& h7": {
      wordBreak: "break-word",
    },
    "& span": {
      wordBreak: "break-all",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    marginRight: "20px",
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "5px",
    },
  },
  profileUpdateRight: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0 30px",
    "& .MuiButton-root": {
      width: "auto",
      height: "40px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  profileUpdateIcon: {
    height: "70px",
    width: "70px",
    flex: "0 0 70px",
    borderRadius: "100%",
    marginRight: "20px",
    "& img": {
      width: "100%",
      height: "100%",
      ObjectFit: "cover",
    },
    "& .MuiAvatar-root": {
      height: "100%",
      width: "100%",
      border: "3px solid #6DEEF3",
    },
    "& .MuiBadge-standard": {
      "& .MuiIconButton-root": {
        background:
          "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
        padding: "2px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "24px",
        padding: "2px",
      },
    },
  },
  accountRight: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 15px 30px",
    },
  },
  myprofileBottom: {
    backgroundImage: "url('/images/account-bg.png')",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    height: "800px",
    padding: "160px 40px 100px",
    marginTop: "50px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media screen and (max-width: 1449px)": {
      backgroundSize: "100% 100%",
    },

    "@media screen and (min-width: 1450px)": {
      backgroundSize: "100% 100%",
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      padding: "0",
    },

    // "& .MuiTypography-h4": {
    //   fontWeight: "400",
    //   fontSize: "12px",
    //   textTransform: "uppercase",
    //   marginBottom: "20px",
    //   textAlign: "center",
    //   marginTop: "70px",
    //   fontFamily: "pirulenregular",
    //   background:
    //     "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    //   WebkitBackgroundClip: "text",
    //   WebkitTextFillColor: "transparent",
    //   position: "absolute",
    //   left: "51%",
    //   top: "62px",
    //   width: "100%",
    //   transform: "translate(-50%)",
    //   [theme.breakpoints.up("xl")]: {
    //     top: "82px",
    //   },

    //   [theme.breakpoints.down("lg")]: {
    //     fontSize: "20px",
    //     marginBottom: "20px",
    //     position: "static",
    //     transform: "none",
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     marginTop: "0",
    //   },
    // },
  },
  // accountBg:{
  //   backgroundImage: "url('/images/account-bg.png')",
  //   backgroundSize: "cover",
  //   backgroundPosition: "center top",
  //   backgroundRepeat: "no-repeat",
  //   minHeight: "600px",
  // },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },

  metamaskBtn: {
    marginBottom: "10px",
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "10px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    marginTop: "40px",
    textTransform: "capitalize",
    minWidth: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
      minWidth: "50%",
    },
  },
  walletAddress: {
    cursor: "pointer",
  },
}));
