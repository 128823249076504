import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  games: [],
  gamesCount: 0,
  numberOfPages: 0,
  gameLink: "",
  moreGamesLoading: false,
  isGameStarted: false,
};

const casinoGamesSlice = createSlice({
  name: "casinoGames",
  initialState,
  reducers: {
    getGamesStart: (state, action) => {
      if (action.payload.loadMoreGames) {
        state.moreGamesLoading = true;
      }
      if (!action.payload.loadMoreGames) {
        state.loading = true;
      }
    },
    getGamesSuccess: (state, action) => {
      if (action.payload.loadMoreGames) {
        state.moreGamesLoading = false;
        state.games = [...state.games, ...action.payload.games];
      }
      if (!action.payload.loadMoreGames) {
        state.loading = false;
        state.games = action.payload.games;
      }
      state.success = true;
      state.numberOfPages = action.payload.numberOfPages;
      state.gamesCount = action.payload.gamesCount;
    },
    getGamesFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    getGameLinkStart: (state) => {
      state.loading = true;
      state.isGameStarted = true;
    },
    getGameLinkSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.gameLink = action.payload;
      state.redirection = false;
      state.isGameStarted = true;
    },
    getGameLinkFailure: (state, action) => {
      state.loading = false;
      state.error = true;
      state.gameLink = "";
      state.redirection = action.payload.redirection;
      state.isGameStarted = false;
    },
    resetIsGameStarted: (state) => {
      state.isGameStarted = false;
    },
    getSearchedGamesStart: (state, action) => {
      if (action.payload.loadMoreSeachedGames) {
        state.moreSearchLoading = true;
      }
      if (!action.payload.loadMoreSeachedGames) {
        state.searchLoading = true;
      }
    },
    getSearchedGamesSuccess: (state, action) => {
      if (action.payload.loadMoreSeachedGames) {
        state.moreSearchLoading = false;
        state.searchGames = [
          ...state.searchGames,
          ...action.payload.searchGames,
        ];
      }
      if (!action.payload.loadMoreSeachedGames) {
        state.searchLoading = false;
        state.searchGames = action.payload.searchGames;
      }
      state.success = true;
      state.searchNoOfPages = action.payload.searchNoOfPages;
      state.searchCount = action.payload.searchCount;
    },
    getSearchedGamesFailure: (state) => {
      state.searchLoading = false;
      state.loading = false;
      state.error = true;
    },
    resetSearchGames: (state) => {
      state.searchGames = [];
      state.searchNoOfPages = 0;
      state.searchCount = 0;
    },
  },
});

export const {
  getGamesStart,
  getGamesSuccess,
  getGamesFailure,
  getGameLinkStart,
  getGameLinkSuccess,
  getGameLinkFailure,
  getSearchedGamesStart,
  getSearchedGamesSuccess,
  getSearchedGamesFailure,
  resetSearchGames,
  resetIsGameStarted,
} = casinoGamesSlice.actions;

export default casinoGamesSlice.reducer;
