export const ACCEPTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const MAX_IMAGE_FILE_SIZE = 1024 * 1024; // 1 MB
export const VIEW_ALL_GAMES_LIMIT = 18;
export const CASINO_LOBBY_GAMES_LIMIT = 12;
export const DATE_FORMAT = "MM/DD/YYYY";
export const TRANSACTION_LIMIT = 10;
export const EAR_TRANSACTION_TYPE = {
  0: "TRANSACTIONS.DEBIT",
  1: "TRANSACTIONS.CREDIT",
  2: "TRANSACTIONS.ROLLBACK",
  3: "TRANSACTIONS.DEPOSIT",
  4: "TRANSACTIONS.WITHDRAW",
  7: "TRANSACTIONS.TIP",
  8: "TRANSACTIONS.DEBIT_NO_CASH",
  9: "TRANSACTIONS.CREDIT_NO_CASH",
  10: "TRANSACTIONS.ROLLBACK_NO_CASH",
  11: "TRANSACTIONS.NON_CASH_BONUS_CLAIM",
  12: "TRANSACTIONS.DEPOSIT_BONUS_CLAIM",
  13: "TRANSACTIONS.TIP_NON_CASH",
  14: "TRANSACTIONS.WITHDRAW_CANCEL",
  15: "TRANSACTIONS.BET_BONUS",
  16: "TRANSACTIONS.REFER_A_FRIEND_BONUS",
  17: "TRANSACTIONS.CASH_BACK_BONUS",
  18: "TRANSACTIONS.SEASONAL_BONUS",
  19: "TRANSACTIONS.JOINING_BONUS",
};
