import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  header: {
    background: "#000000",
    padding: "10px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    zIndex: "3",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  gameheader: {
    position: "relative",
    background: "#000000",
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    columnGap: "0.5rem",
    alignItems: "center",
    width: "100%",
    "& .MuiGrid-root": {
      width: "100%",
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    "& button": {
      color: "white",
      borderRadius: "70%",
      border: "1px solid",
      minWidth: "2rem",
    },
    "& img": {
      top: "0px",
      right: "1rem",
      position: "absolute",
      bottom: "0px",
      margin: "auto",
    },
    "& .MuiInputBase-root": {
      borderRadius: "30px",
      background: "#fff",
      color: "#000",
      height: "40px",
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  logoSize: {
    width: "60px",
  },
  headerLogo: {
    display: "flex",
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        width: "70px",
      },
    },
  },
  headerRight: {
    display: "flex",
  },
  headerSearch: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-root": {
      borderRadius: "30px",
      background: "#fff",
      color: "#000",
      height: "40px",
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        background: "transparent",
        padding: "0",
      },
    },
    "& img": {
      top: "0px",
      right: "10px",
      position: "absolute",
      bottom: "0px",
      margin: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    [theme.breakpoints.down("sm")]: {
      width: "20px",
    },
  },
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "30px",
    padding: "2px 18px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    marginLeft: "10px",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "6px 8px",
      fontSize: "10px",
      height: "34px",
    },
  },
  walletIcon: {
    position: "relative",
    left: "-10px",
    [theme.breakpoints.down("sm")]: {
      left: "-5px",
      width: "20px",
    },
  },
  btnPrimaryLang: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "20px",
    padding: "6px 20px",
    margin: "0px 6px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  balanceBtnWrap: {
    height: "100%",
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      lineHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        padding: "6px",
        fontSize: "10px",
        lineHeight: "21px",
      },
      "& span": {
        marginRight: "15px",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
          marginRight: "3px",
        },
        "& img": {
          [theme.breakpoints.down("sm")]: {
            width: "12px",
          },
        },
      },
    },
  },
  subHeader: {
    padding: "8px 10px",
    background: "#36005B",
    display: "none",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  headerLink: {
    textDecoration: "none",
    color: "#8C80B5",
    textTransform: "uppercase",
    padding: "5px 20px",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      // whiteSpace: "nowrap",
      display: "none",
    },
  },
  activeLink: {
    color: "#ffff",
  },
  myAccountLink: {
    textDecoration: "none",
    color: "#FFFF",
  },
  signupWrap: {
    width: "1000px",
    background: " #091251 !important",
    borderRadius: "10px",
    padding: "0 !important",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  loginWrap: {
    background: " #091251 !important",
    borderRadius: "10px",
    width: "460px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
  },
  languageMenu: {
    "& ul": {
      background: "#6201A6",
      borderRadius: "10px",
      padding: "0",
      width: "150px",
      minWidth: "106px",
      "& li": {
        borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
        fontSize: "14px",
        display: "flex",
        justifyContent: "center",
        fontWeight: "600",
        "&:hover": {
          color: "#FFCE10",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
  accountMenu: {
    "& ul": {
      background: "#040a30",
      borderRadius: "10px",
      padding: "0",
      "& li": {
        borderBottom: "1px solid rgb(255 255 255 / 15%)",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "500",
        "& span": {
          marginRight: "15px",
          "& img": {
            width: "18px",
          },
        },
        "&:hover": {
          color: "#6DEEF3",
        },
        "&:last-child": {
          borderBottom: "none",
        },
      },
    },
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  mobSidebar: {
    background: "#4f0662",
    width: "280px",
    height: "100vh",
    position: "fixed",
    left: "-310px",
    transform: "translateX(-310px)",
    top: "50px",
    zIndex: "1",
    paddingTop: "10px",
    display: "none",
    transition: "all 400ms  ease-in-out",
    "& a": {
      display: "block",
      padding: "15px 20px",
      textDecoration: "none",
      color: "#fff",
      borderBottom: "1px solid #7b0899",
      cursor: "pointer",
      fontSize: "14px",

      "&:hover": {
        color: "#f578c6",
      },
    },
    "&.active": {
      left: "0",
      transform: "translateX(0px)",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  sidebarClose: {
    cursor: "pointer",
    position: "absolute",
    right: "-29px",
    top: "0",
    background: "rgb(247 247 248 / 30%)",
    padding: "8px",
    display: "flex",
    borderRadius: "100%",

    "& img": {
      width: "14px",
    },
  },
  countryFlagImg: {
    marginRight: "5px",
  },
}));
