import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  headerLink: {
    textDecoration: "none",
    color: "#fff",
    textTransform: "uppercase",
    padding: "5px 20px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  },
  activeLink: {
    color: "#FFCE10 !important",
  },
  footerLogo: {
    textAlign: "center",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0 20px",
      textAlign: "center",
    },
  },
  logoSize: {
    width: "60px",
  },
  footerWrap: {
    background: "#000000",
    marginTop: "15px",
    padding: "15px 40px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0 15px",
    },
  },
  footerLinking: {
    padding: "30px 15px",
    borderTop: " 2px solid rgba(255, 255, 255, 0.6)",
    display: "none",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
      justifyContent: "flex-start",
      padding: "10px 0px",
    },
    "& a": {
      textDecoration: "none",
      color: "#fff",
      fontSize: "16px",
      padding: "20px 40px",
      [theme.breakpoints.down("md")]: {
        padding: "10px",
        whiteSpace: "nowrap",
        fontSize: "12px",
      },
    },
  },
  footerCopyright: {
    background: "#000",
    padding: "5px 15px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 15px",
      flexFlow: "column-reverse",
    },
    "& .MuiTypography-body1": {
      width: "100%",
      fontSize: "14px",
      color: "#6B7084",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        textAlign: "center",
        marginTop: "10px",
      },
    },
  },
  socialListing: {
    display: "flex",
    alignItems: "center",
    "& .MuiLink-underlineAlways": {
      margin: "0 5px",
      display: "flex",
    },
  },
  terms: {
    color: "white",
    textDecoration: "none",
    marginRight: "40px",
    "&:hover": {
      color: "#f1c311",
    },
  },
  privacy: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      color: "#f1c311",
    },
  },
  footerBtn: {
    textDecoration: "none",
    color: "#fff",
    textTransform: "uppercase",
    padding: "5px 20px",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
    fontSize: "15.1px",
  },
  cmsLoading: {
    margin: "20px",
  },
  cmsContainer: {
    marginTop: "6.5rem",
    minHeight: "calc(100vh - 276px)",
    padding: "0 3rem",
  },
  mainContainer: {
    minHeight: "100vh",
  },
  quickLinksWrap: {
    display: "flex",
    justifyContent: "center",
    margin: "15px 0 0",
    flexWrap: "wrap",
    "& a": {
      display: "block",
      fontSize: "14px",
      padding: "5px 10px",
      textDecoration: "none",
      textTransform: "uppercase",
      color: " #6B7084",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
      "&:hover": {
        color: "#fff",
      },
      "&.icon18-box": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      // flexFlow: "column",
      alignItems: "center",
    },
  },
  quickLinks: {
    padding: "0 30px",
    "& .MuiTypography-h6": {
      fontSize: "16px",
      color: "#FFC0FB",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  },
  socailLinks: {
    "& a": {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      padding: "5px 0",
      textDecoration: "none",
      textTransform: "uppercase",
      color: " #6B7084",
      "& img": {
        marginRight: "15px",
      },
    },
    "& .MuiTypography-h6": {
      fontSize: "16px",
      color: "#60F0F4",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
  },
  quickLinksScroll: {
    [theme.breakpoints.down("lg")]: {
      overflowX: "auto",
      whiteSpace: "nowrap",
    },
  },
  gameImage: {
    textAlign: "center",
    "& img": {
      padding: "20px",
      width: "18%",
      [theme.breakpoints.down("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },
  },
  footerItem: {
    display: "flex",
    "& ul": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      "& li": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& p": {
          "&:nth-child(1)": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "15px",
            "& img": {
              width: "25px",
            },
          },
          "&:nth-child(2)": {
            flexDirection: "column",
            display: "flex",
          },
          "& a": {
            textDecoration: "none",
            color: "gray",
            paddingBottom: "10px",
          },
        },
      },
    },
  },
}));
