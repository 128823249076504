import axiosClient from "../../../network";

export default class lobbyService {
  static async getProviders() {
    try {
      const { data } = await axiosClient.get("/casino/getAllProvider");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  static async getSubCategory() {
    try {
      const { data } = await axiosClient.get("/casino/getAllSubCategory");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getAllGames(params) {
    try {
      const { data } = await axiosClient.get("/casino/getAllGames", {
        params,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  static async getGameLink(payload) {
    try {
      const { data } = await axiosClient.post("/earGaming/launchGame", payload);
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  static async getAllSearchedGames(params) {
    try {
      const { data } = await axiosClient.get("/casino/getAllFilteredGames", {
        params,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
