import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Pagination,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";
import "dayjs/locale/en";
import i18n from "i18next";
import dayjs from "dayjs";

import useStyles from "./BetHistory.styles";
import { getAllBetsStart } from "../../../redux-saga/redux/profile/transactions";
import {
  DATE_FORMAT,
  EAR_TRANSACTION_TYPE,
  TRANSACTION_LIMIT,
} from "../../../utils/constants";
import { formatCategoryName } from "../../../utils/helper";

const BetHistory = () => {
  const { bets, totalPages, loading } = useSelector(
    (state) => state.transactions
  );
  const { sectionData } = useSelector((state) => state.section);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [date, setDate] = useState({
    fromDate: null,
    endDate: null,
  });

  const transactionFilters = () => {
    const filters = {};
    if (date.fromDate) {
      filters.dateFrom = dayjs(date.fromDate).format(DATE_FORMAT);
    }
    if (date.endDate) {
      filters.dateTo = dayjs(date.endDate).format(DATE_FORMAT);
    }
    return filters;
  };

  useEffect(() => {
    dispatch(getAllBetsStart({ page: 1, limit: TRANSACTION_LIMIT }));
  }, [dispatch]);

  const handlePageChange = (event, value) => {
    const filters = transactionFilters();
    dispatch(
      getAllBetsStart({ ...filters, page: value, limit: TRANSACTION_LIMIT })
    );
    setPage(value);
  };

  const filterData = () => {
    const filters = transactionFilters();
    dispatch(
      getAllBetsStart({ ...filters, page: 1, limit: TRANSACTION_LIMIT })
    );
    setPage(1);
  };

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        {/* <Typography variant="h4" gutterBottom>
          {t("BET_HISTORY.BET_HISTORY")}
        </Typography> */}
        <h4 className="profile-heading">{t("BET_HISTORY.BET_HISTORY")}</h4>
        <br></br>
        <Grid className={classes.myprofileForm}>
          <Grid>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", padding: "10px 0" }}
            >
              {sectionData.map((section) => (
                <Grid key={section.id}> {Parser(section?.content)}</Grid>
              ))}
            </Typography>
          </Grid>
          <Grid className={classes.signupFromFname}>
            <Grid container spacing={2}>
              {/* <Grid item md={4} sm={12}>
                <div className={classes.customBtnBox}>
                  <Button variant="text">Sports Bets</Button>
                  <Typography variant="h6">Casino</Typography>
                </div>
              </Grid> */}
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={i18n?.language || "en"}
              >
                <Grid item md={3} sm={4}>
                  <div className={classes.formGroup}>
                    <DesktopDatePicker
                      id="start-date"
                      label={t("TRANSACTIONS.FROM_DATE")}
                      variant="outlined"
                      disableFuture
                      value={date.fromDate}
                      inputFormat={DATE_FORMAT}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(fromDate) => {
                        setDate((current) => ({
                          ...current,
                          fromDate,
                        }));
                      }}
                      maxDate={date.endDate || null}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={4}>
                  <div className={classes.formGroup}>
                    <DesktopDatePicker
                      id="end-date"
                      label={t("TRANSACTIONS.TO_DATE")}
                      variant="outlined"
                      inputFormat={DATE_FORMAT}
                      disableFuture
                      value={date.endDate}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(endDate) => {
                        setDate((current) => ({
                          ...current,
                          endDate,
                        }));
                      }}
                      minDate={date.fromDate || null}
                    />
                  </div>
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} sm={4}>
                <Button
                  onClick={() => filterData()}
                  className={classes.btnPrimary}
                >
                  {t("COMMON.SHOW")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        {loading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : bets.length > 0 ? (
          <>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("TRANSACTIONS.DATE_TIME")}</TableCell>
                      <TableCell>{t("BET_HISTORY.GAME_TYPE")}</TableCell>
                      <TableCell>{t("BET_HISTORY.GAME_NAME")}</TableCell>
                      <TableCell>{t("BET_HISTORY.TYPE")}</TableCell>
                      <TableCell>{t("TRANSACTIONS.AMOUNT")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bets.map((bet, index) => (
                      <TableRow key={`${bet.transactionId} ${index}`}>
                        <TableCell>
                          {dayjs(bet.createdAt).format(
                            `${DATE_FORMAT} HH:mm:ss`
                          )}
                        </TableCell>
                        <TableCell>
                          {formatCategoryName(
                            bet.MasterCasinoGame.MasterGameSubCategory.name
                          )}
                        </TableCell>
                        <TableCell>{bet.MasterCasinoGame?.name}</TableCell>
                        <TableCell>
                          {t(EAR_TRANSACTION_TYPE[bet.actionId]) || ""}
                        </TableCell>
                        <TableCell>{bet.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Stack alignItems="center">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
              />
            </Stack>
          </>
        ) : (
          <Stack alignItems="center">
            <h2>{t("COMMON.NO_TRANSACTION_MSG")}</h2>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default BetHistory;
