import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: null,
  userDetails: {},
  walletAmount: "",
  walletLoading: false,
  referralId: "",
  affiliateCode: "",
};

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    resetParams(state, action) {
      state.referralId = "";
      state.affiliateCode = "";
    },
    updateParams(state, action) {
      //console.log(action.payload)
      if (action.payload.referralId)
        state.referralId = action.payload?.referralId;
      if (action.payload.affiliateCode)
        state.affiliateCode = action.payload?.affiliateCode;
    },
    getUserProfileDetailsStart: (state, action) => {
      if (action.payload?.walletLoading) {
        state.walletLoading = true;
      }
      state.loading = true;
    },
    getUserProfileDetailsSuccess: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    },
    getUserProfileDetailsFailure: (state, action) => {
      if (action.payload?.walletLoading) {
        state.walletLoading = false;
      }
      state.loading = false;
      state.error = action.payload;
      state.walletLoading = false;
    },
    updateUserProfileStart: (state, action) => {
      state.loading = true;
    },
    updateUserProfileSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.userDetails = action.payload;
    },
    updateUserProfileFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    getWalletBalanceSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletAmount = action.payload;
    },
    startPoll: () => {},
    resetMyProfileState: (state) => {
      state.userDetails = initialState.userDetails;
      state.walletAmount = initialState.walletAmount;
    },
  },
});

export const {
  getUserProfileDetailsStart,
  getUserProfileDetailsSuccess,
  getUserProfileDetailsFailure,
  updateUserProfileStart,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  getWalletBalanceSuccess,
  resetMyProfileState,
  startPoll,
  updateParams,
  resetParams,
} = myProfileSlice.actions;

export default myProfileSlice.reducer;
