import React, { useEffect } from "react";

import { Outlet, Link, useLocation } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useStyles from "./Sidebar.styles";
import sidebarItems from "./Sidebar.list.json";
// import Profile from "./Profile";
import { handleLogout } from "../Header";
import { getSectionStart } from "../../redux-saga/redux/section";

const Sidebar = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;
  const newPath = pathname.split("/").pop();

  console.log("locatonn", newPath);
  useEffect(() => {
    const params = {
      displayPage: newPath,
      contentType: 2,
      language: "es",
    };
    dispatch(getSectionStart(params));
  }, [dispatch]);
  const { loading } = useSelector((state) => state.authentication);

  return (
    <div className={classes.accountWrap}>
      <div className={classes.accountRightWrap}>
        {/* <Grid className={classes.sidebarUserIcon}>
          <img src="/images/user_icon.svg" alt="User Icon" />
        </Grid> */}
        {/* <Profile /> */}
        {sidebarItems.map((item) => {
          // if (!isLoggedIn && item.to === "/user/resetPassword") {
          //   return null;
          // }
          return (
            // <div >
            <Grid key={item.content} className={classes.sidebarListing}>
              <Link to={item.to}>
                {/* <span>
                    <img src={item.src} alt={item.alt} />
                  </span> */}
                {t(item.content)}
              </Link>
            </Grid>
            // </div>
          );
        })}
        <Grid className={classes.sidebarListing}>
          <Link
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            {/* <span>
              <img src="/images/logout.svg" alt="Logout Icon" />
            </span> */}
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              t("HEADER.LOG_OUT")
            )}
          </Link>
        </Grid>
      </div>

      {/* <div className={classes.accountRightWrap}> */}
      <Outlet />
      {/* </div> */}
    </div>
  );
};

export default Sidebar;
