import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useParams } from "react-router-dom";
// import Parser from "html-react-parser";
import { Button, CircularProgress, Grid } from "@mui/material";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import useStyles from "./Promotion.style";
import {
  getAdvertisementStart,
  recordAdvertisementClickStart,
} from "../../redux-saga/redux/advertisement";

export const LeftButton = () => {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);
  return (
    <Button disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      Prev
    </Button>
  );
};

export const RightButton = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Button disabled={isLastItemVisible} onClick={() => scrollNext()}>
      Next
    </Button>
  );
};

const Advertisement = () => {
  //const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, advertisementData } = useSelector(
    (state) => state.advertisement
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdvertisementStart());
  }, [dispatch]);

  const handleClick = (id) => {
    dispatch(
      recordAdvertisementClickStart({
        advertisementsId: id,
      })
    );
  };

  return (
    <>
      {loading ? (
        <Grid textAlign="center">
          <CircularProgress className={classes.cmsLoading} size={50} />
        </Grid>
      ) : (
        <Grid container>
          <ScrollMenu
            LeftArrow={LeftButton}
            RightArrow={RightButton}
            wrapperClassName="horizontalScrollWrapper"
          >
            {advertisementData.map((advertisementData, index) => (
              <button
                onClick={() => handleClick(advertisementData.id)}
                style={{ all: "unset", width: "300px", height: "300px" }}
                key={advertisementData.id}
              >
                <Link to={advertisementData?.url} target="_blank">
                  <img src={advertisementData?.webAdImage} alt="Casino Card" />
                </Link>
              </button>
            ))}
          </ScrollMenu>
        </Grid>
      )}
    </>
  );
};

export default Advertisement;
