import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  advertisementData: [],
};

const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {
    getAdvertisementStart: (state, action) => {
      state.loading = true;
    },
    getAdvertisementSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.advertisementData = action.payload;
    },
    getAdvertisementFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    recordAdvertisementClickStart: (state) => {},
    recordAdvertisementClickSuccess: (state) => {
      state.success = true;
      state.error = false;
    },
    recordAdvertisementClickFailure: (state) => {
      state.success = false;
      state.error = true;
    },
  },
});

export const {
  getAdvertisementStart,
  getAdvertisementSuccess,
  getAdvertisementFailure,
  recordAdvertisementClickStart,
  recordAdvertisementClickSuccess,
  recordAdvertisementClickFailure,
} = advertisementSlice.actions;

export default advertisementSlice.reducer;
