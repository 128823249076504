import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
  },
  myprofileForm: {
    maxWidth: "340px",
    margin: "170px auto 0",
    [theme.breakpoints.down("md")]: {
      margin: "30px auto 0",
      maxWidth: "340px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "30px auto 0",
      maxWidth: "100%",
    },
  },
  resetPasswordWrap: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    height: "100%",
    justifyContent: "center",
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    maxWidth: "400px",
    margin: "130px auto 0",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "0 10px",
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  accountRight: {
    height: "100%",
  },
  myprofileBottom: {
    backgroundImage: "url('/images/account-bg.png')",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    height: "800px",
    padding: "60px 40px 100px",
    marginTop: "50px",
    position: "relative",
    "@media screen and (max-width: 1449px)": {
      backgroundSize: "100% 100%",
    },

    "@media screen and (min-width: 1450px)": {
      backgroundSize: "100% 100%",
    },

    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7d7de2 ",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "#7d7de2",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "20px 15px",
      backgroundImage: "none",
      padding: "110px 10px",
    },
    "& .MuiTypography-h4": {
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      marginBottom: "20px",
      textAlign: "center",
      marginTop: "70px",
      fontFamily: "pirulenregular",
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      position: "absolute",
      left: "51%",
      top: "62px",
      width: "100%",
      transform: "translate(-50%)",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginBottom: "20px",
        position: "static",
        transform: "none",
        marginTop: "20px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
  },
  authBtnWrap: {
    borderTop: "1px solid rgba(255, 255, 255, 0.4)",
    padding: "40px 0 20px 0",
    "& > .MuiGrid-root": {
      maxWidth: "540px",
      margin: "0 auto",
    },
    // "& button": {
    //   fontSize: "20px",
    //   fontWeight: "bold",
    // },
  },
  customDepositWrap: {
    // borderTop: "1px solid rgba(255, 255, 255, 0.4)",
    // padding: "40px 0 0 0",
    "& > .MuiGrid-root": {
      maxWidth: "540px",
      margin: "0 auto",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
    },
  },
  amountInfo: {
    color: "rgba(255, 255, 255, 0.3)",
    marginTop: "-16px",
    marginBottom: "20px",
  },
  selectBtnBox: {
    "& button": {
      background: "transparent",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      color: "rgba(255, 255, 255, 0.3)",
      "&:hover, &:focus, &:active, &.active": {
        background: "#f1c311",
        color: "#000",
      },
    },
  },
  accordionContainer: {
    "& .MuiAccordionSummary-root": {
      background: "transparent",
    },
    "& .MuiPaper-elevation": {
      background: "transparent",
      borderBottom: "2px solid #ffe100",
      borderRadius: "0",
    },
    "& .MuiAccordion-root": {
      "&:before": {
        display: "none",
      },
    },
  },
  currencyIcon: {
    width: "25px",
    height: "25px",
    marginRight: "10px",
  },
  topRow: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  btnWrap: {
    margin: "10px",
    "& button": {
      width: "auto",
      height: "40px",
    },
  },
  scannerImg: {
    textAlign: "center",
  },
  addressInput: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "30px",
    "& .MuiTypography-body1": {
      fontSize: "14px",
      color: "#717171",
      paddingRight: "20px",
      wordBreak: "break-all",
    },
    "& img": {
      cursor: "pointer",
      width: "20px",
    },
  },
  addressText: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "1px solid #ccc",
    paddingBottom: "20px",
    [theme.breakpoints.down("md")]: {
      flexFlow: "column",
    },
    "& .MuiTypography-body1": {
      fontWeight: "500",
      margin: 0,
      fontSize: "14px",
      paddingRight: "10px",
    },
    "& span": {
      fontWeight: "300",
      wordBreak: "break-all",
    },
    "& img": {
      width: "25px",
      cursor: "pointer",
    },
  },

  acceptingCurrency: {
    paddingRight: "0 !important",
    marginLeft: "10px !important",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0 !important",
      marginTop: "10px !important",
    },
  },
  tooltipIcon: {
    height: "20px",
    width: "20px",
    borderRadius: "100%",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    top: "10px",
    right: "10px",
    "& img": {
      height: "12px",
      width: "12px",
    },
  },
  addressTextLeft: {
    display: "flex",
    alignItems: "flex-start",
  },
}));
