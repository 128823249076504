import { combineReducers } from "@reduxjs/toolkit";

import authentication from "./auth/authentication";
import resetPassword from "./auth/resetPassword";
import myProfile from "./profile/myProfile";
import transactions from "./profile/transactions";
import toast from "./toast";
import casinoLobby from "./casino/lobby/casinoLobby";
import casinoGames from "./casino/lobby/casinoGames";
import cms from "./cms";
import payment from "./payment";
import settings from "./settings";
import advertisement from "./advertisement";
import section from "./section";
const rootReducer = combineReducers({
  authentication,
  myProfile,
  transactions,
  toast,
  payment,
  resetPassword,
  casinoLobby,
  casinoGames,
  cms,
  settings,
  advertisement,
  section,
});

export default rootReducer;
