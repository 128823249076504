import React, { useEffect } from "react";

import {
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import QRCode from "react-qr-code";
import { Box } from "@mui/system";

import useStyles from "./Deposit.styles";
import {
  generateAddressStart,
  getCoinsStart,
  setSelectedCoin,
} from "../../../redux-saga/redux/payment";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../../redux-saga/redux/toast";

const Deposit = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const [coinData, setCoinData] = useState();

  const { coinList, generateAddress, generateLoading, selectedCoin } =
    useSelector((state) => state.payment);
  const { sectionData } = useSelector((state) => state.section);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  const coinFilteredList = coinList.filter((coin) => {
    if (coin.name !== "USD" && coin.name !== "EUR" && coin.name !== "USDTT") {
      return coin.name;
    }
  });

  const handleGenerateAddress = (e, currencyCode) => {
    if (!selectedCoin) {
      dispatch(showErrorMessage({ msg: t("COMMON.EMPTY_COIN") }));
      return;
    }
    dispatch(
      generateAddressStart({
        currencyCode,
      })
    );
  };

  const handleCopy = (e, value) => {
    navigator.clipboard.writeText(value);
    dispatch(showSuccessMessage({ msg: "Copied to clipboard" }));
  };

  const handleCoinSelect = (e) => {
    if (e.target.value) {
      dispatch(setSelectedCoin(e.target.value));
    }
  };

  useEffect(() => {
    dispatch(getCoinsStart());
    return () => {
      dispatch(setSelectedCoin(null));
    };
  }, [dispatch]);

  return (
    <div className={classes.accountRightWrap}>
      <Grid className={classes.accountRight}>
        <Grid className={classes.myprofileBottom}>
          <h4 className="profile-heading"> {t("HEADER.DEPOSIT")}</h4>
          <Grid>
            <Typography
              variant="h5"
              sx={{ textAlign: "center", padding: "10px 0" }}
            >
              {sectionData.map((section) => (
                <Grid key={section.id}> {Parser(section?.content)}</Grid>
              ))}
            </Typography>
          </Grid>
          <div className={classes.accordionContainer}>
            <TextField
              className={classes.selectText}
              select
              label={t("PAYMENT.SELECT_COINS")}
              value={selectedCoin}
              onChange={handleCoinSelect}
              fullWidth
            >
              {coinFilteredList &&
                coinFilteredList.map((coin) => (
                  <MenuItem key={coin.masterBlockchainCoinId} value={coin}>
                    {coin.name}
                  </MenuItem>
                ))}
            </TextField>
            {generateAddress && (
              <Grid item className={classes.addressTextWrap}>
                <Grid item className={classes.addressText}>
                  <Grid>
                    <Typography>
                      {t("PAYMENT.DEPOSIT_ADDRESS")} :
                      <span>{generateAddress}</span>
                    </Typography>
                  </Grid>
                  <Box onClick={(e) => handleCopy(e, generateAddress)}>
                    <img src="/images/copying.png" alt="Copy Icon" />
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    backgroundColor: "white",
                    padding: "5px",
                    marginLeft: "15px",
                  }}
                >
                  <QRCode
                    style={{
                      height: "auto",
                    }}
                    value={`${generateAddress}`}
                    size={128}
                  />
                </Grid>
              </Grid>
            )}
            {!generateAddress ? (
              <div className={classes.btnWrap}>
                <button
                  onClick={(e) => handleGenerateAddress(e, selectedCoin?.name)}
                  className={classes.btnPrimary}
                >
                  {generateLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    t("PAYMENT.GENERATE_ADDRESS")
                  )}
                </button>
              </div>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Deposit;
