import { put, takeEvery } from "redux-saga/effects";

import advertisementService from "../../../services/advertisement";
import {
  getAdvertisementStart,
  getAdvertisementSuccess,
  getAdvertisementFailure,
  recordAdvertisementClickStart,
  recordAdvertisementClickSuccess,
  recordAdvertisementClickFailure,
} from "../../redux/advertisement";
import { showErrorMessage } from "../../redux/toast";

function* advertisementWatcher() {
  yield takeEvery(
    [getAdvertisementStart.type, recordAdvertisementClickStart.type],
    advertisementWorker
  );
}

function* advertisementWorker(action) {
  try {
    switch (action.type) {
      case getAdvertisementStart.type: {
        const { data, error } =
          yield advertisementService.getAdvertisementData();
        if (!error) {
          yield put(getAdvertisementSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(getAdvertisementFailure());
        }
        break;
      }

      case recordAdvertisementClickStart.type: {
        const { error } = yield advertisementService.recordAdvertisementData(
          action.payload
        );
        if (!error) {
          yield put(recordAdvertisementClickSuccess());
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(recordAdvertisementClickFailure());
        }
        break;
      }

      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getAdvertisementStart.type: {
        yield put(getAdvertisementFailure());
        break;
      }
      case recordAdvertisementClickStart.type: {
        yield put(recordAdvertisementClickFailure());
        break;
      }

      default: {
        break;
      }
    }
    yield put(showErrorMessage({ msg: error.message }));
  }
}

export default advertisementWatcher;
