import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Tooltip,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import useStyles from "./Withdraw.styles";
import {
  getCoinsStart,
  getConvertedBalanceStart,
  resetConvertedBalance,
  setSelectedCoin,
  withdrawalRequestStart,
} from "../../../redux-saga/redux/payment";
import { isNumeric } from "../../../utils/helper";

export default function Withdraw() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [withdrawalAddress, setWithdrawalAddress] = useState(null);
  const [withdrawalAmount, setWithdrawalAmount] = useState(null);

  const {
    convertLoading,
    coinList,
    convertedBalance,
    withdrawLoading,
    withdrawSuccess,
    selectedCoin,
  } = useSelector((state) => state.payment);
  const { currencyCode } = useSelector((state) => state.myProfile.userDetails);
  const { sectionData } = useSelector((state) => state.section);

  const handleWithdrawalAmount = (e) => {
    setWithdrawalAmount(e.target.value);
  };
  const coinListResult = coinList.filter((coin) => {
    if (coin.name !== "USD" && coin.name !== "USDTT" && coin.name !== "EUR") {
      return coin.name;
    }
  });

  const handleWithdrawalAddress = (e) => {
    // setWithdrawalAddress(parseFloat(e.target.value).toFixed(2));
    setWithdrawalAddress(e.target.value);
  };

  const validateBitcoinAddress = (withdrawalAddress) => {
    const bitcoinRegex = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/;
    return bitcoinRegex.test(withdrawalAddress);
  };

  const validateEthereumAddress = (withdrawalAddress) => {
    const ethereumRegex = /^0x[a-fA-F0-9]{40}$/;
    return ethereumRegex.test(withdrawalAddress);
  };

  const validateUsdtAddress = (withdrawalAddress) => {
    const usdtRegex = /^0x[a-fA-F0-9]{40}$/;
    return usdtRegex.test(withdrawalAddress);
  };
  console.log("wallet", withdrawalAddress);
  const handleShowConversion = () => {
    const validation = [];

    if (!isNumeric(withdrawalAmount)) {
      validation.push(t("COMMON.INVALID_WITHDRAWAL_AMOUNT"));
    }

    if (!withdrawalAddress) {
      validation.push(t("COMMON.EMPTY_WITHDRAWAL_AMOUNT"));
    }

    if (!selectedCoin) {
      validation.push(t("COMMON.EMPTY_COIN"));
    }
    if (selectedCoin) {
      let isValidAddress = false;
      console.log(selectedCoin);

      switch (selectedCoin?.name) {
        case "BTC":
          isValidAddress = validateBitcoinAddress(withdrawalAddress);
          break;
        case "ETH":
          isValidAddress = validateEthereumAddress(withdrawalAddress);
          break;
        case "USDTE":
          isValidAddress = validateUsdtAddress(withdrawalAddress);
          break;
        default:
          isValidAddress = false;
      }
      if (!isValidAddress) {
        validation.push("Please enter valid wallet address");
      }
    }

    if (validation.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, {
          id: message,
        });
      });
      return;
    }
    dispatch(
      getConvertedBalanceStart({
        fiatAmount: withdrawalAmount,
        currencyCode: selectedCoin?.name,
        fiatCurrency: currencyCode,
      })
    );
  };

  const handleReset = () => {
    dispatch(resetConvertedBalance());
    setWithdrawalAddress(null);
    setWithdrawalAmount(null);
  };

  const handleSubmitRequest = () => {
    dispatch(
      withdrawalRequestStart({
        fiatAmount: withdrawalAmount,
        withdrawalAddress,
        withdrawalAmount: `${convertedBalance}`,
        currency: selectedCoin?.name,
      })
    );
  };

  const handleCoinSelect = (e) => {
    if (e.target.value) {
      dispatch(setSelectedCoin(e.target.value));
    }
  };

  useEffect(() => {
    if (withdrawSuccess) {
      setWithdrawalAmount(null);
      setWithdrawalAddress(null);
    }
  }, [withdrawSuccess]);

  useEffect(() => {
    dispatch(getCoinsStart());
    return () => {
      dispatch(resetConvertedBalance());
      dispatch(setSelectedCoin(null));
    };
  }, [dispatch]);

  return (
    <div className={classes.accountRightWrap}>
      <Grid className={classes.myprofileBottom}>
        {/* <Typography variant="h4" gutterBottom>
          {t("HEADER.WITHDRAW")}
        </Typography> */}
        <h4 className="profile-heading"> {t("HEADER.WITHDRAW")}</h4>
        <Grid className={classes.resetPasswordWrap}>
          <Grid>
            <div className={classes.myprofileForm}>
              <Grid>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", padding: "10px 0" }}
                >
                  {sectionData.map((section) => (
                    <Grid key={section.id}> {Parser(section?.content)}</Grid>
                  ))}
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.formGroup}>
                    <TextField
                      id="wallet_address"
                      type="text"
                      label={t("COMMON.WITHDRAWAL_ADDRESS")}
                      value={withdrawalAddress ?? ""}
                      variant="outlined"
                      onChange={handleWithdrawalAddress}
                      disabled={convertedBalance ? true : false}
                    />
                    <Tooltip
                      title={t("COMMON.INFO_TOOLTIP_ADDRESS")}
                      placement="top"
                    >
                      <div className={classes.tooltipIcon}>
                        <img src="/images/info.png" alt="Info Icon" />
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formGroup}>
                    <TextField
                      type="text"
                      id="withdraw"
                      value={withdrawalAmount ?? ""}
                      label={`${t("COMMON.WITHDRAW_AMOUNT")} (USD)`}
                      variant="outlined"
                      placeholder="$"
                      onChange={handleWithdrawalAmount}
                      disabled={convertedBalance ? true : false}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formGroup}>
                    <TextField
                      select
                      variant="outlined"
                      disabled={convertedBalance ? true : false}
                      label={t("PAYMENT.SELECT_COINS")}
                      value={selectedCoin}
                      onChange={handleCoinSelect}
                    >
                      {coinListResult &&
                        coinListResult.map((coin) => (
                          <MenuItem
                            key={coin.masterBlockchainCoinId}
                            value={coin}
                          >
                            {coin?.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>
                </Grid>
                {convertedBalance ? (
                  <>
                    {convertedBalance >= 0.0002 ? (
                      <Grid item xs={12}>
                        <div className={classes.formGroup}>
                          <TextField
                            label={t("COMMON.CONVERTED_AMOUNT")}
                            variant="outlined"
                            disabled
                            value={convertedBalance}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <div className={classes.formGroup}>
                          *{t("COMMON.INVALID_WITHDRAWAL_AMOUNT_VALUE")}
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <div className={classes.formGroup}>
                        <Button
                          className={classes.btnPrimary}
                          onClick={handleReset}
                        >
                          {t("COMMON.RESET")}
                        </Button>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <div className={classes.formGroup}>
                      <Button
                        className={classes.btnPrimary}
                        onClick={handleShowConversion}
                        disabled={convertLoading}
                      >
                        {convertLoading ? (
                          <CircularProgress size="1.5rem" />
                        ) : (
                          <>{t("COMMON.SHOW_CONVERSION")}</>
                        )}
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
        {convertedBalance ? (
          <Grid className={classes.authBtnWrap}>
            <Grid>
              <Button
                className={classes.btnPrimary}
                onClick={handleSubmitRequest}
                disabled={withdrawLoading}
              >
                {withdrawLoading ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <>{t("COMMON.SUBMIT_REQUEST")}</>
                )}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
