import { put, takeLatest, delay } from "redux-saga/effects";

import authenticationService from "../../../services/authentication";
import Metamask from "../../../services/metamask";
import session from "../../../utils/session";
import {
  connectMetamaskFailure,
  connectMetamaskStart,
  connectMetamaskSuccess,
  forgotPasswordFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  logoutFailure,
  logoutStart,
  logoutSuccess,
  refreshLoginFailure,
  refreshLoginStart,
  refreshLoginSuccess,
  verifyEmailFailure,
  verifyEmailStart,
  verifyEmailSuccess,
  verifyPasswordTokenFailure,
  verifyPasswordTokenStart,
  verifyPasswordTokenSuccess,
  walletConnectLoginFailure,
  walletConnectLoginStart,
  walletConnectLoginSuccess,
} from "../../redux/auth/authentication";
import {
  resetMyProfileState,
  resetParams,
} from "../../redux/profile/myProfile";
import { resetPaymentState } from "../../redux/payment/";
import { showErrorMessage, showSuccessMessage } from "../../redux/toast";
import { resetTransactionState } from "../../redux/profile/transactions";

function* authenticationWatcher() {
  yield takeLatest(walletConnectLoginStart.type, wallletConnectLoginWorker);
  yield takeLatest(refreshLoginStart.type, refreshLoginWorker);
  yield takeLatest(connectMetamaskStart.type, connectedMetamaskWorker);
  yield takeLatest(logoutStart.type, logoutWorker);
  yield takeLatest(verifyEmailStart.type, verifyEmailWorker);
  yield takeLatest(forgotPasswordStart.type, forgotPasswordWorker);
  yield takeLatest(verifyPasswordTokenStart.type, verifyPasswordTokenWorker);
}

function* wallletConnectLoginWorker(action) {
  try {
    const { data, error } = yield authenticationService.walletConnectLogin(
      action.payload
    );
    if (data) {
      const { token, resToken, isMetaMaskUser, userId, user } = data;
      session.saveSessionCred({
        token,
        resToken,
        isMetaMaskUser,
        userId,
        user,
      });
      yield put(showSuccessMessage({ tKey: "HEADER.LOGIN_SUCCESS" }));
      yield put(walletConnectLoginSuccess(userId));
    }

    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(walletConnectLoginFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(walletConnectLoginFailure());
  }
}

function* refreshLoginWorker() {
  try {
    const { data, error } = yield authenticationService.refreshLogin();
    if (data) {
      const { token, resToken, isMetaMaskUser, user } = data;
      const { userId } = session.getSessionCred;
      session.saveSessionCred({
        token,
        resToken,
        isMetaMaskUser,
        userId,
        user,
      });
      yield put(refreshLoginSuccess({ userId }));
    }

    if (error) {
      yield put(refreshLoginFailure(error.message));
      yield delay(1500);
      session.clearSessionCred();
      window.location.href = "/";
    }
  } catch (error) {
    yield put(refreshLoginFailure(error.message));
    yield delay(1500);
    session.clearSessionCred();
    window.location.href = "/";
  }
}

function* connectedMetamaskWorker(action) {
  try {
    const { data, error } = yield Metamask.connectToMetamask(action.payload);
    if (data) {
      yield put(
        showSuccessMessage({ tKey: "HEADER.METAMASK_CONNECT_SUCCESS" })
      );
      yield put(connectMetamaskSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(connectMetamaskFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(connectMetamaskFailure());
  }
}

// function* metamaskLoginWorker() {
//   try {
//     const { data, error } = yield Metamask.requestAccount();
//     if (data) {
//       const { token, resToken, isMetaMaskUser, walletAddress } = data;
//       session.saveSessionCred({
//         token,
//         resToken,
//         isMetaMaskUser,
//       });
//       yield put(showSuccessMessage({ tKey: "HEADER.METAMASK_LOGIN_SUCCESS" }));
//       yield put(metamaskLoginSuccess(walletAddress));
//     }
//     if (error) {
//       yield put(showErrorMessage({ msg: error.message }));
//       yield put(metamaskLoginFailure());
//     }
//   } catch (error) {
//     yield put(showErrorMessage({ msg: error.message }));
//     yield put(metamaskLoginFailure());
//   }
// }

// function* signUpWorker(action) {
//   try {
//     const { data, error } = yield authenticationService.signUp(action.payload);
//     if (data) {
//       yield put(
//         showSuccessMessage({
//           tKey: data.emailUsedInSignup
//             ? "HEADER.EMAIL_SUCCESS"
//             : "HEADER.USERNAME_SIGNUP_SUCCCESS",
//         })
//       );
//       yield put(signUpSuccess());
//     }
//     if (error) {
//       yield put(showErrorMessage({ msg: error.message }));
//       yield put(signUpFailure());
//     }
//   } catch (error) {
//     yield put(showErrorMessage({ msg: error.message }));
//     yield put(signUpFailure());
//   }
// }

function* verifyEmailWorker(action) {
  try {
    const { data, error } = yield authenticationService.verifyEmail(
      action.payload
    );
    if (data?.emailVerified) {
      yield put(verifyEmailSuccess());
    }
    if (error) {
      yield put(verifyEmailFailure(error.message));
    }
  } catch (error) {
    yield put(verifyEmailFailure(error.message));
  }
}

function* forgotPasswordWorker(action) {
  try {
    const { data, error } = yield authenticationService.forgotPassword(
      action.payload
    );
    if (data) {
      yield put(
        showSuccessMessage({ tKey: "HEADER.FORGOT_PASSWORD_EMAIL_MSG" })
      );
      yield put(forgotPasswordSuccess());
    }
    if (error) {
      yield put(forgotPasswordFailure(error.message));
    }
  } catch (error) {
    yield put(forgotPasswordFailure(error.message));
  }
}

function* verifyPasswordTokenWorker(action) {
  try {
    const { data, error } = yield authenticationService.verifyPasswordToken(
      action.payload
    );
    if (data) {
      yield put(
        showSuccessMessage({ tKey: "CHANGE_PASSWORD.PASSWORD_CHANGE_SUCCESS" })
      );
      yield put(verifyPasswordTokenSuccess());
    }
    if (error) {
      yield put(verifyPasswordTokenFailure(error.message));
    }
  } catch (error) {
    yield put(verifyPasswordTokenFailure(error.message));
  }
}

function* logoutWorker() {
  try {
    yield put(resetParams({ referralId: null, affiliateCode: null }));
    session.clearSessionCred();
    console.log("clearing the session");
    yield put(showSuccessMessage({ tKey: "SIDEBAR.LOG_OUT_SUCCESS" }));
    yield put(logoutSuccess());
    yield put(resetMyProfileState());
    yield put(resetPaymentState());
    yield put(resetTransactionState());
  } catch (error) {
    yield put(logoutFailure(error.message));
  }
}

export default authenticationWatcher;
