import React, { useEffect, useState, useRef } from "react";

import HomeIcon from "@mui/icons-material/Home";
import {
  Button,
  Grid,
  // DialogContent,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { disconnect } from "@wagmi/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Person,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useAccount, useBalance } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
// import Modal from "../../HOC/modal/Modal";
// import WalletConnect from "./WalletConnect";
import { isMobile } from "react-device-detect";

import useStyles from "./Header.styles";
import session from "../../utils/session";
import {
  logoutStart,
  refreshLoginStart,
  refreshTokenExpire,
  resetSuccess,
  walletConnectLoginStart,
  // resetSuccess,
} from "../../redux-saga/redux/auth/authentication";
import headerMenu from "./HeaderMenu.list.json";
import Wallet from "./Wallet";
import Languages from "./Languages.list.json";
import { store } from "../../redux-saga/store";
import SearchGames from "../../container/Casino/SearchGames";
import { resetIsGameStarted } from "../../redux-saga/redux/casino/lobby/casinoGames";

const handleLogout = async () => {
  try {
    await disconnect();
    store.dispatch(logoutStart());
  } catch (err) {
    console.log(err);
  }
};

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { referralId, affiliateCode } = useSelector((state) => state.myProfile);
  const { t } = useTranslation();
  const location = useLocation();
  const offset = useRef(0);
  const { open } = useWeb3Modal();
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const navigate = useNavigate();
  const { refreshTokenExpired } = useSelector((state) => state.authentication);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);

  const [currencyToken, setCurrencyToken] = useState("");
  const { isLoggedIn, success } = useSelector((state) => state.authentication);
  const { orientation } = useSelector((state) => state.settings);
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  const { isConnected, address } = useAccount();
  const balance = useBalance({
    address: address,
    onSuccess(data) {
      console.log(balance, "Success", data.symbol);
      setCurrencyToken(data.symbol);
    },
  });
  const openAccount = Boolean(anchorElAccount);
  const openLanguage = Boolean(anchorElLanguage);

  const handleOpenAccount = (event) => {
    setAnchorElAccount(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleCloseLanguage = (event, language) => {
    i18n.changeLanguage(language);
    setAnchorElLanguage(null);
  };

  useEffect(() => {
    if (!isLoggedIn && success && !isConnected) {
      setAnchorElAccount(null);
      dispatch(resetSuccess());
      navigate("/");
    }
  }, [isLoggedIn, success, isConnected, dispatch, navigate]);

  useEffect(() => {
    if (refreshTokenExpired) {
      handleLogout();
      dispatch(refreshTokenExpire(false));
    }
  }, [refreshTokenExpired, navigate, dispatch]);

  useEffect(() => {
    if (
      isConnected &&
      address &&
      currencyToken &&
      !session.getSessionCred &&
      !success
    ) {
      dispatch(
        walletConnectLoginStart({
          publicAddress: address,
          token: currencyToken,
          referralCode: referralId ? referralId : null,
          affiliateCode: affiliateCode ? affiliateCode : null,
        })
      );
    }
  }, [
    isConnected,
    address,
    currencyToken,
    success,
    dispatch,
    referralId,
    affiliateCode,
  ]);

  useEffect(() => {
    if (offset.current !== 1) {
      if (session.getSessionCred && !isLoggedIn) {
        dispatch(refreshLoginStart());
      }
      offset.current = 1;
    }
  }, [dispatch, isLoggedIn]);

  if (isGameStarted && isMobile) {
    if (orientation === "PORTRAIT") {
      return (
        <Grid className={classes.gameheader}>
          <HomeIcon
            fontSize="large"
            onClick={() => {
              dispatch(resetIsGameStarted());
              navigate("/");
            }}
          />
          <SearchGames />
        </Grid>
      );
    }
    return;
  }

  return (
    <div>
      <Grid className={classes.header}>
        <Grid className={classes.headerLeft}>
          <Link to="/" className={classes.headerLogo}>
            <img
              className={classes.LogoSize}
              src="/images/brand-logo.svg"
              alt="Brand Logo"
            />
          </Link>
          <Link
            className={`${classes.headerLink} ${
              location.pathname === "/" ? classes.activeLink : null
            }`}
            to="/"
          >
            {t("HEADER.CASINO")}
          </Link>
          {/* <Link
            className={`${classes.headerLink} ${
              location.pathname === "/promotions" ? classes.activeLink : null
            }`}
            to="/promotions"
          >
            Promotions
          </Link> */}
        </Grid>
        <Grid className={classes.headerRight}>
          <Grid className={classes.headerSearch}>
            <SearchGames />
          </Grid>
          {/* {!isLoggedIn && (
            <Button
              className={classes.btnPrimary}
              onClick={handleWalletConnect}
            >
              <img
                src="/images/deposit-icon.svg"
                className={classes.walletIcon}
                alt="Wallet"
              />
              Connect Wallet
            </Button>
          )} */}
          {!isLoggedIn && (
            <Button className={classes.btnPrimary} onClick={() => open()}>
              <img
                src="/images/deposit-icon.svg"
                className={classes.walletIcon}
                alt="Wallet"
              />
              <span>Connect Wallet</span>
            </Button>
          )}
          <Button
            id="language-button"
            className={classes.btnPrimaryLang}
            aria-controls={openLanguage ? "language-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openLanguage ? "true" : undefined}
            onClick={(event) => setAnchorElLanguage(event.currentTarget)}
            startIcon={
              <img
                align="right"
                src={`/images/${
                  Languages?.[i18n.language?.toUpperCase() || "EN"]?.flag
                }.svg`}
                alt="country flag"
                className={classes.countryFlagImg}
              />
            }
            endIcon={openLanguage ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            {Languages?.[i18n.language?.toUpperCase() || "EN"]?.name}
          </Button>
          <Menu
            id="language-menu"
            anchorEl={anchorElLanguage}
            open={openLanguage}
            onClose={() => setAnchorElLanguage(null)}
            className={classes.languageMenu}
            MenuListProps={{
              "aria-labelledby": "language-button",
            }}
          >
            <MenuItem onClick={(event) => handleCloseLanguage(event, "en")}>
              <img
                src="/images/united-kingdom-flag-icon.svg"
                alt="country flag"
                className={classes.countryFlagImg}
              />
              English
            </MenuItem>
            <MenuItem onClick={(event) => handleCloseLanguage(event, "es")}>
              <img
                src="/images/argentina-flag-icon.svg"
                align="left"
                alt="country flag"
                className={classes.countryFlagImg}
              />
              Español
            </MenuItem>
          </Menu>

          {isLoggedIn ? (
            <>
              <Wallet />
              <Button
                id="account-button"
                className={classes.btnPrimary}
                aria-controls={openAccount ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? "true" : undefined}
                onClick={handleOpenAccount}
                startIcon={<Person />}
                endIcon={
                  openAccount ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                }
              >
                {t("HEADER.ACCOUNT")}
              </Button>
              <Menu
                id="account-menu"
                anchorEl={anchorElAccount}
                open={openAccount}
                className={classes.accountMenu}
                onClose={handleCloseAccount}
                MenuListProps={{
                  "aria-labelledby": "account-button",
                }}
              >
                {headerMenu.map((item) => {
                  return (
                    <MenuItem key={item.to} onClick={handleCloseAccount}>
                      <Link className={classes.myAccountLink} to={item.to}>
                        <span>
                          <img src={item.src} alt={item.alt} />
                        </span>
                        {t(item.content)}
                      </Link>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogout();
                  }}
                >
                  <span>
                    {" "}
                    <img src="/images/logout.svg" alt="logout Icon" />{" "}
                  </span>
                  {t("HEADER.LOG_OUT")}
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </Grid>
        {/* <Modal
          isShowing={openWalletConnect}
          content={
            <DialogContent className={classes.signupWrap}>
              <WalletConnect handleWalletConnect={handleWalletConnect} />
            </DialogContent>
          }
          hide={handleWalletConnect}
        /> */}
        {/* MOBILE SIDEBAR START FROM HERE */}
        <Grid className={classes.mobSidebar}>
          <Grid className={classes.sidebarClose}>
            <img src="images/close.png" alt="Close icon" />
          </Grid>
          <Link
            className={`${classes.headerLink} ${
              location.pathname === "/" ? classes.activeLink : null
            }`}
            to="/"
          >
            {t("HEADER.CASINO")}
          </Link>
          {isLoggedIn ? (
            <>
              <Link
                className={`${classes.headerLink} ${
                  location.pathname === "/user/betHistory"
                    ? classes.activeLink
                    : null
                }`}
                to="/user/betHistory"
              >
                {t("HEADER.MY_BETS")}
              </Link>
            </>
          ) : null}
        </Grid>
        {/* MOBILE SIDEBAR END HERE */}
      </Grid>
      <Grid className={classes.subHeader}>
        <Link className={classes.headerLink} to="sport-bet">
          {t("HEADER.SPORTS_BETS")}
        </Link>
        <Link
          className={`${classes.headerLink} ${
            location.pathname === "/" ? classes.activeLink : null
          }`}
          to="/"
        >
          {t("HEADER.CASINO")}
        </Link>
        {isLoggedIn ? (
          <>
            <Link
              className={`${classes.headerLink} ${
                location.pathname === "/user/betHistory"
                  ? classes.activeLink
                  : null
              }`}
              to="/user/betHistory"
            >
              {t("HEADER.MY_BETS")}
            </Link>
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default Header;
export { handleLogout };
