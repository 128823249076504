import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "#FFCE10",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background: "#f1c311",
    },
  },
  myprofileForm: {
    margin: "0 auto",
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  accountRight: {
    height: "100%",
  },
  myprofileBottom: {
    backgroundImage: "url('/images/account-bg.png')",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    height: "800px",
    padding: "160px 40px 100px",
    marginTop: "50px",
    "@media screen and (max-width: 1449px)": {
      backgroundSize: "100% 100%",
    },

    "@media screen and (min-width: 1450px)": {
      backgroundSize: "100% 100%",
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7d7de2 ",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "#7d7de2",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "20px 15px",
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
    "& .MuiTypography-h4": {
      fontWeight: "400",
      fontSize: "16px",
      textTransform: "uppercase",
      marginBottom: "20px",
      textAlign: "center",
      marginTop: "70px",
      fontFamily: "pirulenregular",
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        marginBottom: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
      },
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "20px",
      fontWeight: "bold",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  bonusCard: {
    background: "transparent",
    borderRadius: "10px",
    boxShadow: "none",
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
    "& .bgImg": {
      width: "100%",
      maxWidth: "100%",
      height: "auto",
    },
    "& .contentBox": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px 24px",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      "& .topText": {
        fontWeight: "600",
        textAlign: "center",
        textTransform: "uppercase",
      },
      "& .middleText": {
        fontWeight: "700",
        textAlign: "center",
        textTransform: "uppercase",
        "& span": {
          fontSize: "24px",
          display: "block",
        },
      },
      "& button": {
        marginBottom: "6px",
      },
    },
  },
}));
