import React from "react";

import { Dialog, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

import useStyles from "./Modal.styles";

export default function Modal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { isShowing, hide, content, maxWidth, className } = props;

  // function getHeader() {
  //   if (typeof header === "function") {
  //     return header();
  //   }

  //   return header;
  // }

  function getContent() {
    if (typeof content === "function") {
      return content();
    }

    return content;
  }

  if (!isShowing) {
    return null;
  }

  // const modalHeader = getHeader();
  const modalContent = getContent();

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        hide();
      }}
      aria-labelledby="customized-dialog-title"
      open={isShowing}
      maxWidth={maxWidth}
      fullWidth={maxWidth ? true : false}
      className={clsx(className, [classes.dialogBox])}
      // PaperProps={{
      //   style: {
      //     background:
      //       "transparent linear-gradient(1deg, #1A1821 0%, #342D35 100%) 0% 0% no-repeat padding-box",
      //   },
      // }}
    >
      {/* <DialogTitle className={classes.dialogTitle} disableTypography>
        {modalHeader}

        <Button onClick={hide}>
          <CloseIcon />
        </Button>
      </DialogTitle> */}
      <Box onClick={hide} className={classes.closeIconWrap}>
        <CloseIcon />
      </Box>
      {/* <DialogContent> */}
      {modalContent}

      {/* </DialogContent> */}
    </Dialog>
  );
}
