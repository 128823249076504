export const sidebarListItems = [
  {
    //   listIcon: "/images/sidebar-home.svg",
    //   alt: "Home icon",
    listText: "Home",
    to: "/",
  },
  {
    //   listIcon: "/images/sidebar-casino.svg",
    //   alt: "Casino icon",
    listText: "Casino",
    to: "/",
  },
  // {
  //   listText: "Promotion",
  //   to: "/promotions",
  // },
];
