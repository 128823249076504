import React, { useEffect } from "react";

import { Grid, Typography, Button, TextField } from "@mui/material";
import Parser from "html-react-parser";
import { toast } from "react-hot-toast";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./Referral.styles";
import { getUserProfileDetailsStart } from "../../../redux-saga/redux/profile/myProfile";
async function copyContent(copiedtext) {
  try {
    await navigator.clipboard.writeText(copiedtext);
    toast.success(t("COMMON.REFFERAL_CODE_COPIED_MSG"), {
      id: "IMAGE_UPDATE_SUCCESS",
    });
    console.log("Content copied to clipboard");
    /* Resolved - text copied to clipboard successfully */
  } catch (err) {
    console.error("Failed to copy: ", err);
    /* Rejected - text failed to copy to the clipboard */
  }
}
const Referral = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.myProfile);
  const { sectionData } = useSelector((state) => state.section);
  useEffect(() => {
    //call api to get referral code
    dispatch(getUserProfileDetailsStart());
  }, [dispatch]);

  return (
    <div className={classes.accountRight}>
      <Grid className={classes.myprofileBottom}>
        <Typography className="profile-heading" variant="h4" gutterBottom>
          REFERRAL
        </Typography>
        {/* <h4 className="profile-heading"> {t("MYPROFILE.MY_PROFILE")}</h4> */}
        <br></br>
      </Grid>
      <Grid
        className={classes.myprofileForm}
        sx={{ position: "relative", top: "-35rem" }}
      >
        <Grid>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", padding: "10px 0" }}
          >
            {sectionData.map((section) => (
              <Grid key={section.id}> {Parser(section?.content)}</Grid>
            ))}
          </Typography>
        </Grid>
        <Grid className={classes.signupFromFname}>
          <Grid className={classes.referFriends}>
            <Typography className="titleText">
              Refer your Friends{" "}
              <span>Earn ${userDetails?.referBonus} each</span>
            </Typography>
            <img
              src="/images/referral-coins-bag.png"
              className="coinsBagImg"
              alt="Coins Bag Icon"
            />
          </Grid>
          <Grid className={classes.totalRewards}>
            <div className="innerBox">
              <div className="rewardTextBox">
                <img src="/images/referral-coin.png" alt="Coin Icon" />
                <Typography className="rewardText">
                  Total Rewards{" "}
                  <span>
                    $
                    {userDetails?.earningsFromRefer
                      ? userDetails?.earningsFromRefer
                      : 0}
                  </span>
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid className={classes.codeReferFriend}>
            <Typography className="rewardText">
              <span>Refer and earn bonus for each referral</span>
              If you refer this code to any user & user signup with your
              referral code then you will get 100% bonus in your account.
            </Typography>
            <Grid className="referFriendInput">
              <TextField
                id="outlined-basic"
                value={document.location.origin + "/" + userDetails?.uuid}
                disabled
              />
            </Grid>
          </Grid>
          <Grid className={classes.authBtnWrap}>
            <Button
              className={classes.btnPrimary}
              onClick={() =>
                copyContent(document.location.origin + "/" + userDetails?.uuid)
              }
            >
              Copy Referral Code
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Referral;
