import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  debounce,
  Select,
  MenuItem,
  CircularProgress,
  Button,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import useStyles from "./ViewAll.styles";
import { getGamesStart } from "../../../redux-saga/redux/casino/lobby/casinoGames";
import { formatCategoryName } from "../../../utils/helper";
import {
  getProvidersStart,
  getSubCategoryStart,
} from "../../../redux-saga/redux/casino/lobby/casinoLobby";
import { VIEW_ALL_GAMES_LIMIT } from "../../../utils/constants";
import Game from "../Game";

const element = document.getElementsByTagName("html")[0];
const ViewAll = () => {
  const classes = useStyles();
  const { provider, category, name } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const offset = useRef(1);
  const initialLoad = useRef(0);
  const { subCategory, providers } = useSelector((state) => state.casinoLobby);
  const { games, loading, gamesCount, moreGamesLoading } = useSelector(
    (state) => state.casinoGames
  );

  const handleChangeSearch = (event) => {
    setSearchInput(event.target.value);
    searchGames(event.target.value);
  };

  useEffect(() => {
    if (provider !== "false") {
      dispatch(
        getGamesStart({
          providerId: provider,
          limit: VIEW_ALL_GAMES_LIMIT,
          pageNumber: 1,
          isMobile,
        })
      );
      dispatch(getSubCategoryStart());
    }
    if (category !== "false") {
      dispatch(
        getGamesStart({
          subCategoryId: category,
          limit: VIEW_ALL_GAMES_LIMIT,
          pageNumber: 1,
          isMobile,
        })
      );
      dispatch(getProvidersStart());
    }
    initialLoad.current = 1;
  }, [dispatch, provider, category]);

  const getGames = ({
    searchValue = null,
    providerId = null,
    categoryId = null,
  }) => {
    if (initialLoad.current !== 2) {
      initialLoad.current += 1;
    }
    let filters = {
      limit: VIEW_ALL_GAMES_LIMIT,
      search: searchInput || null,
    };
    if (searchValue !== null) {
      filters.search = searchValue || null;
      filters.subCategoryId = selectedCategory || null;
      filters.providerId = selectedProvider || null;
    }
    if (provider !== "false") {
      filters.providerId = provider;
      if (searchValue === null) {
        filters.subCategoryId = categoryId || null;
      }
    }
    if (category !== "false") {
      filters.subCategoryId = category;
      if (searchValue === null) {
        filters.providerId = providerId || null;
      }
    }
    filters.pageNumber = 1;
    filters.isMobile = isMobile;
    dispatch(getGamesStart({ ...filters }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce((searchValue) => {
      getGames({ searchValue });
      offset.current = 1;
    }, 500),
    [selectedProvider, selectedCategory]
  );

  const handleMoreGames = () => {
    offset.current += 1;
    dispatch(
      getGamesStart({
        limit: VIEW_ALL_GAMES_LIMIT,
        search: searchInput || null,
        providerId:
          (provider !== "false" ? provider : selectedProvider) || null,
        subCategoryId:
          (category !== "false" ? category : selectedCategory) || null,
        pageNumber: offset.current,
        loadMoreGames: true,
        isMobile,
      })
    );
  };

  const handleChangeCategory = (event) => {
    offset.current = 1;
    getGames({ categoryId: event.target.value });
    setSelectedCategory(event.target.value);
  };

  const handleChangeProvider = (event) => {
    offset.current = 1;
    getGames({ providerId: event.target.value });
    setSelectedProvider(event.target.value);
  };

  const resetFilter = () => {
    setSelectedProvider(0);
    setSelectedCategory(0);
    setSearchInput("");
    offset.current = 1;
    dispatch(
      getGamesStart({
        limit: VIEW_ALL_GAMES_LIMIT,
        providerId: provider !== "false" ? provider : null,
        subCategoryId: category !== "false" ? category : null,
        pageNumber: offset.current,
        isMobile,
      })
    );
  };

  return (
    <div className={classes.myclass}>
      <Grid className={classes.contentWrap}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.innerHeading}
        >
          <Grid item className={classes.innerHeadingLeft}>
            <img src="/images/star-icon.svg" alt="Star Icon" />
            <Typography variant="h6" gutterBottom>
              {formatCategoryName(name)}
            </Typography>
          </Grid>
          <Grid className={classes.viewAllFilter}>
            <Grid item className={classes.searchField}>
              <TextField
                id="search"
                className={classes.searchInput}
                type="text"
                variant="outlined"
                disabled={!!(initialLoad.current === 1 && !(games.length > 0))}
                value={searchInput}
                onChange={handleChangeSearch}
                autoComplete="search"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src="/images/search.svg" alt="search" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item className={classes.homeFilter}>
              <Grid className={classes.homeFilterContent}>
                {provider !== "false" ? (
                  <Grid item className="inputField">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      disabled={
                        !(subCategory?.length > 0) ||
                        (initialLoad.current === 1 && !(games.length > 0))
                      }
                      className={classes.searchInput}
                      onChange={handleChangeCategory}
                      onOpen={() => element.classList.add("disable-scroll")}
                      onClose={() => element.classList.remove("disable-scroll")}
                    >
                      <MenuItem value={0}>{t("COMMON.CATEGORY")}</MenuItem>
                      {subCategory?.length > 0 &&
                        subCategory.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.masterGameSubCategoryId}
                          >
                            {formatCategoryName(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                ) : (
                  <Grid item className={classes.primaryInputBtn}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedProvider}
                      disabled={
                        !(providers?.length > 0) ||
                        (initialLoad.current === 1 && !(games.length > 0))
                      }
                      variant="outlined"
                      className={classes.searchInput}
                      onChange={handleChangeProvider}
                      onOpen={() => element.classList.add("disable-scroll")}
                      onClose={() => element.classList.remove("disable-scroll")}
                    >
                      <MenuItem value={0}>{t("COMMON.PROVIDER")}</MenuItem>
                      {providers?.length > 0 &&
                        providers.map((item) => (
                          <MenuItem
                            key={item.name}
                            value={item.masterCasinoProviderId}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                )}
                <Grid className={classes.filterBtnWrap}>
                  <Button
                    disabled={
                      !!(initialLoad.current === 1 && !(games.length > 0))
                    }
                    onClick={() => resetFilter()}
                    className={classes.btnPrimary}
                  >
                    {t("COMMON.RESET")}
                  </Button>
                  <Link className={classes.btnPrimary} to="/">
                    <Typography>
                      <img src="/images/home-icon.png" alt="home Icon" />{" "}
                      {t("COMMON.LOBBY")}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.viewAllWrap}>
          {loading ? (
            <Typography className={classes.circulerLoader}>
              <CircularProgress size={50} />
            </Typography>
          ) : games?.length > 0 ? (
            games.map((game, index) => (
              <Game
                key={`${game.masterCasinoGameId}${index}`}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                gameId={game.masterCasinoGameId}
                providerName={game?.MasterCasinoProvider?.name || null}
                categoryName={
                  provider !== "false"
                    ? formatCategoryName(game?.MasterGameSubCategory?.name)
                    : formatCategoryName(name)
                }
              />
            ))
          ) : (
            <Grid className={classes.noGamesMsg}>
              <h1>{t("LOBBY.NO_GAMES_MSG")}</h1>
            </Grid>
          )}
        </Grid>
        <Grid textAlign="center">
          {!moreGamesLoading && !loading && gamesCount > games?.length && (
            <Button
              onClick={() => handleMoreGames()}
              className={`${classes.btnPrimary} ${classes.loadMoreButton}`}
            >
              {t("LOBBY.LOAD_MORE_GAMES")}
            </Button>
          )}
          {moreGamesLoading && (
            <Typography>
              <CircularProgress className={classes.moreGamesLoader} size={50} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewAll;
