import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
  },
  homeFilter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    "& .inputField, & button, & a": {
      margin: "5px",
    },
    "& .MuiInputBase-colorPrimary": {
      width: "auto",
      background: "#FFF",
      borderRadius: "10px",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "200px",
        fontSize: "12px",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px",
      background: "#fff",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
    "& .MuiButtonBase-root ": {
      height: "43px",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 40px",
        height: "38px",
        fontSize: "12px",
        margin: "0 !important",
      },
    },
    "& a": {
      textDecoration: "none",
      height: "43px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "0",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 20px",
        height: "38px",
      },
      "& p": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        fontWeight: "600",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
        "& img": {
          width: "20px",
          marginRight: "5px",
        },
      },
    },
    "& .MuiGrid-item": {
      paddingTop: "0",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  primaryInputBtn: {
    margin: "5px",
    "& .MuiSelect-select": {
      borderRadius: "4px",
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-colorPrimary": {
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        margin: "0",
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "4px",
        border: "none",
      },
    },

    [theme.breakpoints.down("sm")]: {
      margin: "5px 0",
      paddingLeft: "0 !important",
      width: "100%",
    },
    "& .MuiInputBase-input": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },

  homeFilterContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      alignItems: "flex-Start",
      width: "100%",
    },
  },
  contentWrap: {
    minHeight: "550px",
    padding: "100px 20px 40px",
    [theme.breakpoints.down("md")]: {
      padding: "70px 0px 30px 0",
    },
  },
  innerHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
      overflowX: "auto",
      paddingRight: "10px",
    },
  },
  innerHeadingLeft: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "5px",
      width: "20px",
    },
    "& .MuiTypography-h6": {
      margin: "0",
      color: "#FFF",
      fontWeight: "bold",
      fontSize: "24px",
    },
  },
  noGamesMsg: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  circulerLoader: {
    margin: "auto",
  },
  loadMoreButton: {
    marginTop: "40px",
  },
  moreGamesLoader: {
    marginBottom: "-20px",
    marginTop: "40px",
  },
  resetBtn: {
    height: "44px",
  },
  lobbyBtnIcon: {
    verticalAlign: "-0.2em",
  },
  searchField: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    "& .MuiInputBase-colorPrimary": {
      width: "auto",
      background: "#FFF",
      borderRadius: "10px",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "200px",
        fontSize: "12px",
      },
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
        width: "100%",
        fontSize: "12px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "10px",
      },
    },
    "& .MuiInputBase-input": {
      padding: "10px",
      background: "#fff",
      borderRadius: "10px",
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      paddingLeft: "0",
    },
    "& .MuiTextField-root": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  filterBtnWrap: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      width: "100%",
    },
    "& a": {
      [theme.breakpoints.down("sm")]: {
        margin: "5px 0",
      },
    },
  },
  viewAllWrap: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },
  viewAllFilter: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
      width: "100%",
    },
  },
}));
