import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
  },
  myprofileForm: {
    maxWidth: "320px",
    margin: "70px auto 0",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      maxWidth: "100%",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "400px",
    // margin: "80px auto 0",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "7px",
    fontSize: "10px",
    textDecoration: "none",
    color: "rgba(255, 255, 255, 0.3)",
    cursor: "pointer",
  },
  accountRight: {
    height: "100%",
  },
  myprofileBottom: {
    backgroundImage: "url('/images/account-bg.png')",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    height: "800px",
    padding: "160px 40px 100px",
    marginTop: "50px",
    position: "relative",
    "@media screen and (max-width: 1449px)": {
      backgroundSize: "100% 100%",
    },

    "@media screen and (min-width: 1450px)": {
      backgroundSize: "100% 100%",
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7d7de2 ",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "#7d7de2",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "20px 15px",
      backgroundImage: "none",
      margin: "0",
      padding: "40px 15px",
    },
    "& .MuiTypography-h4": {
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      marginBottom: "20px",
      textAlign: "center",
      marginTop: "70px",
      fontFamily: "pirulenregular",
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      position: "absolute",
      left: "51%",
      top: "62px",
      width: "100%",
      transform: "translate(-50%)",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginBottom: "20px",
        position: "static",
        transform: "none",
      },
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "14px",
    },
  },
  resetPasswordWrap: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
    height: "100%",
    justifyContent: "center",
  },
}));
