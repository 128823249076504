import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  cmsData: [],
};

const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    getCmsStart: (state, action) => {
      state.loading = true;
    },
    getCmsSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.cmsData = action.payload;
    },
    getCmsFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { getCmsStart, getCmsSuccess, getCmsFailure } = cmsSlice.actions;

export default cmsSlice.reducer;
