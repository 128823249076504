import axiosClient from "../../network";

export default class cmsService {
  static async getSectionData(params) {
    console.log(params, "test");

    try {
      const { data } = await axiosClient.get("/user/getSection", {
        params,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }

    // try {
    //   const { data } = await axiosClient.get("/user/getSection", { action });
    //   return data;
    // } catch (error) {
    //   if (error.data) {
    //     return { error: error.data };
    //   }
    //   return {
    //     error,
    //   };
    // }
  }
}
