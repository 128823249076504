import React, { useEffect, useRef } from "react";

import { CircularProgress, Grid, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import { useTranslation } from "react-i18next";

import useStyles from "./IframeGame.styles";
import {
  getGameLinkFailure,
  getGameLinkStart,
} from "../../../redux-saga/redux/casino/lobby/casinoGames";

const IframeGame = () => {
  const classes = useStyles();
  const iframeRef = useRef();
  const { gameLink, loading, redirection } = useSelector(
    (state) => state.casinoGames
  );
  const { state } = useLocation();
  const gameId = state;
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isGameStarted } = useSelector((state) => state.casinoGames);
  useEffect(() => {
    if (redirection) {
      dispatch(getGameLinkFailure({ redirection: false }));
      setTimeout(() => {
        navigate("/");
      }, 300);
    }
  }, [redirection, navigate, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getGameLinkStart({
        gameId: parseInt(gameId),
        isMobile,
      })
    );
  }, [dispatch, gameId]);

  const RequestFullScreen = () => {
    iframeRef.current.requestFullscreen();
  };

  return (
    <>
      {loading ? (
        <Grid className={classes.iframeCirculerLoader} textAlign="center">
          <CircularProgress size={60} />
        </Grid>
      ) : (
        <>
          <div
            className={
              isGameStarted && isMobile ? classes.gameWrap : classes.iframeWrap
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={9}>
                <Grid className={classes.iframeContent}>
                  {/* <div ref={iframeRef}> */}
                  <iframe
                    src={gameLink?.play_for_real}
                    ref={iframeRef}
                    title="nux"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    // onLoad={(e) => {
                    //   e.target.style.height = e.target.contentDocument.body.scrollHeight + "px";
                    //   e.target.style.width = e.target.contentDocument.body.scrollWidth + "px";
                    // }}
                  />
                  {/* </div> */}
                  <Grid className={classes.iframeBtnWrap}>
                    <Button
                      className={classes.btnPrimary}
                      onClick={RequestFullScreen}
                    >
                      <img src="/images/full-screen.png" alt="Full Screen" />
                    </Button>
                    <Button className={classes.btnPrimary}>
                      <img src="/images/iframe-heart.png" alt="Heart" />
                    </Button>
                    <Button
                      className={classes.btnPrimary}
                      onClick={() => navigate("/lobby")}
                    >
                      <img src="/images/iframe-close.png" alt="Cross" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {!isMobile && (
                <Grid item xs={12} lg={3}>
                  <Grid className={classes.iframeGameWrap}>
                    <Grid className={classes.iframeGameCard}>
                      <img src="/images/ifrmae-game-1.png" alt="game Card" />
                    </Grid>
                    <Grid className={classes.iframeGameCard}>
                      <img src="/images/ifrmae-game-2.png" alt="game Card" />
                    </Grid>
                    <Grid className={classes.iframeGameCard}>
                      <img src="/images/ifrmae-game-3.png" alt="game Card" />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default IframeGame;
