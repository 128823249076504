import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  //   homeBanner: {
  //     backgroundImage: "url('/images/banner.jpg')",
  //     backgroundSize: "cover",
  //     backgroundPosition: "center top",
  //     backgroundRepeat: "no-repeat",
  //     minHeight: "360px",
  //   },
  //   bannerContentSubText: {
  //     display: "flex",
  //     alignItems: "center",
  //     [theme.breakpoints.down("sm")]: {
  //       flexFlow: "column",
  //       alignItems: "flex-start",
  //     },
  //   },
  promotionWrap: {
    marginTop: "5rem",
    padding: "40px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 15px",
    },
    minHeight: "calc(100vh - 286px)",
    [theme.breakpoints.down("md")]: {
      marginTop: "3rem",
    },
    "& button": {
      "& img": {
        minWidth: "300px",
        minHeight: "300px",
      },
    },
  },
  promotionCard: {
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",
  },
  promotionCta: {
    background: "#fff",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  promotionImg: {
    height: "200px",

    "& img": {
      width: "100%",
      height: "100%",
      ObjectFit: "cover",
    },
  },
  promotionOverlayText: {
    position: "absolute",
    bottom: "70px",
    left: "20px",
    "& .MuiTypography-body1": {
      fontSize: "20px",
      fontWeight: "600",
      // maxWidth:"300px",
    },
  },

  btnPrimary: {
    background: "#f379c6",
    borderRadius: "10px",
    padding: "6px 20px",
    color: "#fff",
    fontSize: "12px",
    fontWeight: "600",
    height: "36px",
    flexGrow: "1",
    marginRight: "20px",
    textTransform: "capitalize",
    width: "auto",
    "&:hover": {
      background: "#bc438e",
    },
  },
  btnSecondary: {
    boxShadow: "inset 0 0 0 2px #4f0662",
    borderRadius: "30px",
    padding: "4px 15px",
    color: "#4f0662",
    fontSize: "12px",
    fontWeight: "600",
    height: "36px",
    textTransform: "capitalize",
    "&:hover": {
      background: "#f379c6",
      color: "#fff",
    },
  },
}));
