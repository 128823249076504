import { put, takeEvery } from "redux-saga/effects";

import lobbyService from "../../../../services/casino/lobby";
import {
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  getSubCategoryStart,
  getSubCategorySuccess,
  getSubCategoryFailure,
} from "../../../redux/casino/lobby/casinoLobby";
import { showErrorMessage } from "../../../redux/toast";

function* casinoLobbyWatcher() {
  yield takeEvery(
    [getProvidersStart.type, getSubCategoryStart.type],
    casinoLobbyWorker
  );
}

function* casinoLobbyWorker(action) {
  try {
    switch (action.type) {
      case getProvidersStart.type: {
        const { data, error } = yield lobbyService.getProviders();
        if (!error) {
          yield put(getProvidersSuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(getProvidersFailure());
        }
        break;
      }

      case getSubCategoryStart.type: {
        const { data, error } = yield lobbyService.getSubCategory();
        if (!error) {
          yield put(getSubCategorySuccess(data));
        }
        if (error) {
          yield put(showErrorMessage({ msg: error.message }));
          yield put(getSubCategoryFailure());
        }
        break;
      }

      default: {
        break;
      }
    }
  } catch (error) {
    switch (action.type) {
      case getProvidersStart.type: {
        yield put(getProvidersFailure());
        break;
      }
      case getSubCategoryStart.type: {
        yield put(getSubCategoryFailure());
        break;
      }

      default:
        break;
    }
    yield put(showErrorMessage({ msg: error.message }));
  }
}

export default casinoLobbyWatcher;
