import { put, takeLatest } from "redux-saga/effects";

import paymentService from "../../../services/payment";
import {
  generateAddressFailure,
  generateAddressStart,
  generateAddressSuccess,
  getCoinsFailure,
  getCoinsStart,
  getCoinsSuccess,
  getConvertedBalanceFailure,
  getConvertedBalanceStart,
  getConvertedBalanceSuccess,
  getCurrencyListFailure,
  getCurrencyListStart,
  getCurrencyListSuccess,
  withdrawalRequestFailure,
  withdrawalRequestStart,
  withdrawalRequestSuccess,
} from "../../redux/payment";
import { showErrorMessage, showSuccessMessage } from "../../redux/toast";

function* paymentWatcher() {
  yield takeLatest(getCoinsStart.type, coinWorker);
  yield takeLatest(getCurrencyListStart.type, currencyWorker);
  yield takeLatest(generateAddressStart.type, generateAddressWorker);
  yield takeLatest(getConvertedBalanceStart.type, convertedBalanceWorker);
  yield takeLatest(withdrawalRequestStart.type, withdrawalRequestWorker);
}

function* coinWorker() {
  try {
    const { data } = yield paymentService.getCoins();
    yield put(getCoinsSuccess(data));
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getCoinsFailure(error.message));
  }
}

function* currencyWorker(action) {
  try {
    const { data } = yield paymentService.getAllCurrency(action.payload);
    yield put(getCurrencyListSuccess(data));
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getCurrencyListFailure(error.message));
  }
}

function* generateAddressWorker(action) {
  try {
    const { data } = yield paymentService.generateAddress(action.payload);
    yield put(generateAddressSuccess(data));
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(generateAddressFailure(error.message));
  }
}

function* convertedBalanceWorker(action) {
  try {
    const { data } = yield paymentService.getConvertedBalance(action.payload);
    yield put(getConvertedBalanceSuccess(data));
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getConvertedBalanceFailure(error.message));
  }
}

function* withdrawalRequestWorker(action) {
  try {
    const { data } = yield paymentService.withdrawalRequest(action.payload);
    if (data.status === "pending") {
      yield put(showSuccessMessage({ msg: "Transaction request initiated." }));
      yield put(withdrawalRequestSuccess(data));
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(withdrawalRequestFailure(error.message));
  }
}

export default paymentWatcher;
