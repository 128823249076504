import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
  },
  myprofileForm: {
    margin: "0 auto",
    maxWidth: "48rem",
  },
  signupFromFname: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
  },
  accountRight: {
    height: "100%",
  },
  myprofileBottom: {
    backgroundImage: "url('/images/account-bg.png')",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    height: "800px",
    padding: "160px 40px 100px",
    marginTop: "50px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media screen and (max-width: 1449px)": {
      backgroundSize: "100% 100%",
    },

    "@media screen and (min-width: 1450px)": {
      backgroundSize: "100% 100%",
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      padding: "0",
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7d7de2 ",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "#7d7de2",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "20px 15px",
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
    "& .MuiTypography-h4": {
      lineHeight: "18px",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      marginBottom: "20px",
      textAlign: "center",
      fontFamily: "pirulenregular",
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      // [theme.breakpoints.up("xxl")]: {
      //   marginTop: "56px",
      // },
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
  },
  authBtnWrap: {
    maxWidth: "540px",
    margin: "15px auto -5px auto",
  },
  referFriends: {
    width: "100%",
    backgroundColor: "#030924",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "40px",
    // margin: "0 -40px",
    "& .titleText": {
      width: "100%",
      color: "#ffff",
      fontSize: "20px",
      fontWeight: "600",
      "& span": {
        fontSize: "40px",
        display: "block",
      },
    },
    "& .coinsBagImg": {
      width: "auto",
      height: "167px",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  totalRewards: {
    width: "100%",
    background: "linear-gradient(to top, #091251 50%, #000923 50%)",
    padding: "0 80px",
    // margin: "0 -40px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      marginTop: "-20px",
    },
    "& .innerBox": {
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 40px",
      "& .rewardTextBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "& .rewardText": {
          width: "100%",
          color: "#666",
          fontSize: "16px",
          fontWeight: "500",
          marginLeft: "16px",

          "& span": {
            color: "#000",
            fontSize: "36px",
            fontWeight: "600",
            lineHeight: "40px",
            display: "block",
          },
        },
      },
      "& button": {
        width: "45px",
        minWidth: "45px",
        height: "45px",
        borderRadius: "50%",
      },
    },
  },
  codeReferFriend: {
    textAlign: "center",
    marginTop: "40px",
    "& .MuiTextField-root": {
      width: "100%",
      margin: "15px 0",
    },
    "& .MuiInputBase-root ": {
      background: "#00134f",
      borderRadius: "10px",
      "& .MuiInputBase-input": {
        height: "1.4375em",
        padding: "16.5px 14px",
      },
    },
    "& .rewardText": {
      width: "82%",
      margin: "15px auto",
      "& span": {
        display: "block",
        marginBottom: "16px",
      },
    },
    "& .referFriendInput": {
      maxWidth: "540px",
      margin: "0 auto",
      position: "relative",
      "& img": {
        position: "absolute",
        right: "30px",
        top: "30px",
        cursor: "pointer",
      },
    },
  },
}));
