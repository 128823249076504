import { makeStyles } from "@mui/styles";
// import { padding } from "@mui/system";

export default makeStyles((theme) => ({
  dialogBox: {
    "& .MuiDialog-paperWidthsm": {
      minWidth: "100%",
      minHeight: "90%",
      backgroundColor: "#091251",
    },
    // "& .MuiPaper-rounded": {
    //   maxWidth: "900px",
    //   minWidth: "900px",
    //   borderRadius: "30px",
    //   padding: "20px 40px",
    //   overflow: "visible",
    //   [theme.breakpoints.down("md")]: {
    //     maxWidth: "90%",
    //     minWidth: "90%",
    //     margin: "15px",
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     margin: "10px",
    //     padding: "10px",
    //   },
    // },
    "& .MuiPaper-rounded": {
      maxWidth: "100%",
    },
    // "& .MuiPaper-rounded": {
    //   maxWidth: "816px",
    //   minWidth: "816px",
    //   borderRadius: "16px",
    //   padding: "0",
    //   overflow: "visible",
    //   [theme.breakpoints.down("md")]: {
    //     maxWidth: "90%",
    //     minWidth: "90%",
    //     margin: "15px",
    //     height: "auto",
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     margin: "10px",
    //     padding: "10px",
    //   },
    // },
    "& .MuiDialogContent-root": {
      overflowX: "hidden",
      padding: "0px",
      background: theme?.palette?.background?.default,
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIconWrap: {
    top: "22px",
    right: "14px",
    width: "8px",
    height: "8px",
    display: "flex",
    padding: "8px",
    position: "absolute",
    borderRadius: "100%",
    justifyContent: "center",
    alignItems: "center",
    opacity: "1",
    color: "#ffff",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "26px",
    },
    [theme.breakpoints.down("sm")]: {
      right: "10px",
      width: "20px",
      height: "20px",
    },
  },
}));
