import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  btnPrimary: {
    background: "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
    borderRadius: "4px",
    padding: "6px 20px",
    color: "#000",
    fontSize: "14px",
    fontWeight: "600",
    height: "45px",
    textTransform: "capitalize",
    width: "100%",
    "&:hover": {
      background:
        "linear-gradient(270deg, #6DEEF3  0%, #F9BFF9 48%, #7F80DF 96%)",
    },
  },
  myprofileForm: {
    margin: "0 auto",
    [theme.breakpoints.up("lg")]: {
      padding: "0 10px",
      minWidth: "80%",
      maxWidth: "80%",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 10px",
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  signupFromFname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    padding: "20px 0",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
        [theme.breakpoints.down("md")]: {
          marginRight: "0",
        },
      },
    },
    "& .MuiGrid-container": {
      justifyContent: "space-between",
      width: "100%",
      marginLeft: "0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
    // "& .MuiGrid-item": {
    //   paddingLeft: "0 !important",
    // },
  },
  formGroup: {
    position: "relative",
    marginBottom: "20px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  accountRight: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "40px 15px",
    },
  },
  myprofileBottom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "url('/images/account-bg.png')",
    backgroundSize: "contain",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    height: "800px",
    padding: "160px 40px 100px",
    marginTop: "50px",
    position: "relative",
    "@media screen and (max-width: 1449px)": {
      backgroundSize: "100% 100%",
    },

    "@media screen and (max-width: 1199px)": {
      height: "auto",
    },

    "@media screen and (min-width: 1450px)": {
      backgroundSize: "100% 100%",
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7d7de2 ",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "#7d7de2",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "20px 15px",
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
    "& .MuiTypography-h4": {
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      marginBottom: "20px",
      textAlign: "center",
      marginTop: "70px",
      fontFamily: "pirulenregular",
      background:
        "linear-gradient(270deg, #7F80DF 0%, #F9BFF9 48%, #6DEEF3 96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      position: "absolute",
      left: "51%",
      top: "62px",
      width: "100%",
      transform: "translate(-50%)",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginBottom: "0px",
        position: "static",
        transform: "none",
      },
    },
    [theme.breakpoints.down("lg")]: {
      backgroundImage: "none",
      margin: "0",
      padding: "0",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "80%",
      transform: "translateX(12%)",
    },
  },
  tableContainer: {
    // margin: "0 -40px",
    // whiteSpace: "nowrap",
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
    "& table": {
      "& thead": {
        "& tr": {
          "& th": {
            background: "#030924",
            borderBottom: "0",
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            background: " #091251",
            borderBottom: "0",
          },
        },
      },
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 10px",
      maxWidth: "80%",
      minWidth: "80%",
      position: "relative",
      top: "-25rem",
      margin: "0 auto",
      left: "0.5rem",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 10px",
      maxWidth: "100%",
      minWidth: "100%",
    },
    "& h2": {
      fontSize: "14px",
    },
  },
}));
