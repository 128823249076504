import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useStyles from "./MyProfile.styles";
import { formatWalletAddress } from "../../../utils/helper";

const WalletAddress = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const { walletAddress, metamaskConnected } = useSelector(
    (state) => state.authentication
  );

  return (
    <>
      <Grid>
        {walletAddress && metamaskConnected ? (
          <Typography
            variant="h7"
            className={classes.walletAddress}
            gutterBottom
            onClick={() => setShow((curr) => !curr)}
          >
            <b>{t("MYPROFILE.WALLET_ADDRESS")} : </b>
            {formatWalletAddress(show, walletAddress)}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

export default WalletAddress;
