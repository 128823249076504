import React from "react";

import useStyles from "./Promotion.style";
import Advertisement from "./Advertisement";

const Promotions = () => {
  const classes = useStyles();
  return (
    <div className={classes.promotionWrap}>
      <Advertisement />
    </div>
  );
};

export default Promotions;
