import axiosClient from "../../network";

export default class advertisementService {
  static async getAdvertisementData() {
    try {
      const { data } = await axiosClient.get("/user/advertisements");
      console.log("axios run...", data);
      return data;
    } catch (error) {
      console.log(error);
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  static async recordAdvertisementData(params) {
    try {
      const { data } = await axiosClient.post("/user/advertisementClicks", {
        advertisementsId: params.advertisementsId,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
